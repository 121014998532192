import { useCallback, useMemo, useState } from "react";
import {
    deleteDevice,
    unlinkDeviceFromProject,
} from "../../actions/deviceAction";
import useAppDispatch from "../../hooks/useAppDispatch";
import {
    Box,
    Flex,
    IconButton,
    Tooltip,
    VStack,
    useDisclosure,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import Device from "../../models/device";
import { DataTable } from "../../components/shared/table/DataTable";
import { AddIcon, DeleteIcon, EditIcon, EmailIcon, MinusIcon, SettingsIcon, InfoIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import DeviceConfigurationModal from "./DeviceConfigurationModal";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { fetchProjectsByOrganization } from "../../actions/projectAction";
import LinkDeviceToProjectModal from "./LinkDeviceToProjectModal";
import DeviceMapsModal from "./DeviceMapsModal";
import { BiMap } from "react-icons/bi";
import useCustomAbility from "../../hooks/useCustomAbility";

const columnHelper = createColumnHelper<Device>();

interface DeviceListProps {
    devices: Device[]
}

const DeviceList: React.FC<DeviceListProps> = ({ devices }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const ability = useCustomAbility();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenLink, onOpen: onOpenLink, onClose: onCloseLink } = useDisclosure();
    const { isOpen: isOpenMap, onOpen: onOpenMap, onClose: onCloseMap } = useDisclosure();
    const [selectedDevice, setSelectedDevice] = useState<Device | null>(null);
    const projects = useSelector((state: RootState) => state.project.projects);

    const handleNotificationClick = useCallback(
        (deviceId: string, projectId: string) => {
            navigate(`/projects/${projectId}/notifications/${deviceId}`);
        },
        [navigate]
    );

    const handleConfigurationClick = useCallback(
        (device: Device) => {
            setSelectedDevice(device);
            if (device.configuration !== null) {
                onOpen();
            }
        },
        [onOpen]
    );

    const handleEditClick = useCallback(
        (deviceId: string) => {
            navigate(`/devices/${deviceId}/edit`);
        },
        [navigate]
    );

    const handleUnlinkFromProject = useCallback(
        (device: Device) => {
            if (device.id && device.projectId) {
                dispatch(
                    unlinkDeviceFromProject({
                        deviceId: device.id,
                        projectId: device.projectId,
                    })
                );
            }
        },
        [dispatch]
    );

    const handleLinkToProject = useCallback((device: Device) => {
        if (device.id) {
            setSelectedDevice(device);
            dispatch(fetchProjectsByOrganization({ organizationId: device.organizationId }));
            onOpenLink();
        }
    }, [dispatch, onOpenLink]);

    const handleDeleteDevice = useCallback((device: Device) => {
        if (device.id) {
            dispatch(deleteDevice({ deviceId: device.id }));
        }
    }, [dispatch]);

    const handleMapClick = useCallback((device: Device) => {
        setSelectedDevice(device);
        onOpenMap();
    }, [onOpenMap]);

    const columns = useMemo(
        () => [
            columnHelper.accessor("name", {
                cell: (info) => info.getValue(),
                header: "Name",
            }),
            columnHelper.accessor("deviceHardwareId", {
                cell: (info) => info.getValue() ? info.getValue() : "",
                header: "Device Hardware Id",
            }),
            columnHelper.accessor("isLinked", {
                cell: (info) => (info.getValue() ? "Yes" : "No"),
                header: "Is linked to project",
            }),
            columnHelper.accessor("isActivated", {
                cell: (info) => (info.getValue() ? "Yes" : "No"),
                header: "Is activated",
            }),
            columnHelper.accessor("lastPing", {
                cell: (info) => {
                    if (!info.getValue()) {
                        return "";
                    }
                    const dateValue = info.getValue();
                    const formattedDate = dateValue
                        ? new Date(dateValue).toLocaleString("ro-RO", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                        })
                        : "";

                    return formattedDate;
                },
                header: "Last ping",
            }),
            columnHelper.display({
                id: "buttons",
                cell: ({ row }) => (
                    <Flex alignItems="center">
                        {row.original.geolocationLatitude && row.original.geolocationLongitude && (
                            <Tooltip
                                label="Show device location"
                                aria-label="location"
                                placement="top"
                            >
                                <IconButton
                                    icon={<BiMap />}
                                    aria-label="Location"
                                    colorScheme="gray"
                                    variant="outline"
                                    onClick={() =>
                                        handleMapClick(row.original)
                                    }
                                ></IconButton>
                            </Tooltip>
                        )}
                        {row.original.configuration && ability.can("Update", "Device") && (
                            <Tooltip
                                label="Show device configuration"
                                aria-label="configuration"
                                placement="top"
                            >
                                <IconButton
                                    icon={<SettingsIcon />}
                                    ml={4}
                                    aria-label="Configuration"
                                    colorScheme="gray"
                                    variant="outline"
                                    onClick={() =>
                                        handleConfigurationClick(row.original)
                                    }
                                ></IconButton>
                            </Tooltip>
                        )}

                        {row.original.isActivated && row.original.isLinked && ability.can("List", "Projects_Notifications") && (

                            <Tooltip
                                label="Show device notifications"
                                aria-label="notifications"
                                placement="top"
                            >
                                <IconButton
                                    ml={4}
                                    icon={<EmailIcon />}
                                    aria-label="notifications"
                                    colorScheme="gray"
                                    variant="outline"
                                    onClick={() =>
                                        handleNotificationClick(
                                            row.original.deviceHardwareId,
                                            row.original.projectId as string
                                        )
                                    }
                                ></IconButton>
                            </Tooltip>
                        )
                        }
                        {row.original.isActivated && ability.can("Update", "Device") && (
                            <Tooltip
                                label="Edit device"
                                aria-label="edit"
                                placement="top"
                            >
                                <IconButton
                                    ml={4}
                                    icon={<EditIcon />}
                                    aria-label="edit"
                                    colorScheme="gray"
                                    variant="outline"
                                    onClick={() =>
                                        handleEditClick(
                                            row.original.id,
                                        )
                                    }
                                ></IconButton>
                            </Tooltip>

                        )
                        }
                        {
                            row.original.isLinked && ability.can("Unlink", "Device_From_Project") && (
                                <Tooltip
                                    label="Unlink device from project"
                                    aria-label="unlink"
                                    placement="top"
                                >
                                    <IconButton
                                        ml={4}
                                        icon={<MinusIcon />}
                                        aria-label="unlink"
                                        colorScheme="red"
                                        variant="outline"
                                        onClick={() =>
                                            handleUnlinkFromProject(
                                                row.original
                                            )
                                        }
                                    ></IconButton>
                                </Tooltip>
                            )
                        }
                        {!row.original.isLinked && ability.can("Link", "Device_To_Project") && (
                            <Tooltip
                                label="Link device to project"
                                aria-label="link"
                                placement="top"
                            >
                                <IconButton
                                    ml={4}
                                    icon={<AddIcon />}
                                    aria-label="link"
                                    colorScheme="green"
                                    variant="outline"
                                    onClick={() =>
                                        handleLinkToProject(row.original)
                                    }
                                ></IconButton>
                            </Tooltip>
                        )}
                        {ability.can("Delete", "Device") && (
                            <Tooltip
                                label="Delete device"
                                aria-label="delete"
                                placement="top"
                            >
                                <IconButton
                                    ml={4}
                                    icon={<DeleteIcon />}
                                    aria-label="delete"
                                    colorScheme="red"
                                    variant="outline"
                                    onClick={() =>
                                        handleDeleteDevice(row.original)
                                    }
                                ></IconButton>
                            </Tooltip>
                        )}
                    </Flex >
                ),
            }),
        ],
        [handleConfigurationClick, handleDeleteDevice, handleEditClick, handleLinkToProject, handleNotificationClick, handleUnlinkFromProject, handleMapClick]
    );

    return (
        <VStack spacing={8} alignItems="start">
            <Box width="100%" overflowX="auto">
                <DataTable data={devices} columns={columns} />
                {selectedDevice?.configuration && ability.can("Update", "Device") && (
                    <DeviceConfigurationModal
                        configuration={selectedDevice!.configuration}
                        isOpen={isOpen}
                        onClose={onClose}
                    />
                )}
                {selectedDevice?.id && projects && ability.can("Link", "Device_To_Project") && (
                    <LinkDeviceToProjectModal
                        isOpen={isOpenLink}
                        onClose={onCloseLink}
                        device={selectedDevice}
                        projects={projects}
                    />
                )}

                {selectedDevice?.geolocationLatitude && selectedDevice?.geolocationLongitude && (
                    <DeviceMapsModal
                        device={selectedDevice}
                        isOpen={isOpenMap}
                        onClose={onCloseMap}
                    />
                )
                }
            </Box>
        </VStack>
    );
};

export default DeviceList;
