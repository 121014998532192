import { useNavigate, useParams } from "react-router-dom";
import Organization from "../../models/organization";
import { useCallback, useEffect, useMemo } from "react";
import {
    fetchOrganization,
    updateOrganization,
} from "../../actions/organizationAction";
import useStateHandling from "../../hooks/useStateHandling";
import useAppDispatch from "../../hooks/useAppDispatch";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import Joi from "joi";
import { Box, Heading } from "@chakra-ui/react";
import StatusHandler from "../../components/shared/StatusHandler";
import Form from "../../components/form";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import useCustomAbility from "../../hooks/useCustomAbility";

const organizationSchema = Joi.object({
    name: Joi.string().required(),
    email: Joi.string()
        .email({ tlds: { allow: false } })
        .required(),
    phoneNumber: Joi.string().required(),
});

type OrganizationEditParams = {
    id: string;
};

const OrganizationEdit: React.FC = () => {
    const { status, error } = useStateHandling("organization");
    const { id } = useParams<OrganizationEditParams>();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const organization = useSelector(
        (state: RootState) => state.organization.organizationFull
    );

    const ability = useCustomAbility();

    const methods = useForm({
        resolver: joiResolver(organizationSchema),
    });

    useEffect(() => {
        if (!ability.can("Update", "Organization")) {
            navigate("/");
        } else {
            if (id) {
                dispatch(fetchOrganization({ id: id }));
            }
        }
    }, [dispatch, id, navigate]);

    const organizationFields = useMemo(
        () => [
            {
                name: "name",
                label: "Name",
                type: "text",
                required: true,
                value: organization?.organization.name as string | undefined,
            },
            {
                name: "email",
                label: "Email",
                type: "email",
                required: true,
                value: organization?.organization.email,
            },
            {
                name: "phoneNumber",
                label: "Phone number",
                type: "tel",
                required: true,
                value: organization?.organization.phoneNumber,
            },
        ],
        [organization]
    );

    const handleFormSubmit = useCallback(
        (data: Organization) => {
            if (id) {
                dispatch(
                    updateOrganization({
                        id: id,
                        name: data.name,
                        email: data.email,
                        phoneNumber: data.phoneNumber,
                    })
                );
            }
            navigate("/organizations");
        },
        [dispatch, id, navigate]
    );

    return (
        <Box>
            <StatusHandler status={status} error={error}>
                <Heading>Edit organization</Heading>
                <Box mt={6} width="100%" overflowX="auto">
                    <Form
                        fields={organizationFields}
                        onSubmit={handleFormSubmit}
                        submitText="Save"
                        methods={methods}
                    />
                </Box>
            </StatusHandler>
        </Box>
    );
};

export default OrganizationEdit;
