import { mapAsyncThunkToGlobalAction } from "../actions";
import {
    CreateDeviceData,
    DeleteDeviceData,
    FetchDeviceData,
    FetchDevicesData,
    FetchDevicesWithNotifications,
    FetchDevicesWithNotificationsData,
    LinkDeviceData,
    UnlinkDeviceData,
    UpdateDeviceData,
    createDeviceAdmin,
    deleteDevice,
    fetchDevice,
    fetchDevicesAdmin,
    linkDeviceToProject,
    unlinkDeviceFromProject,
    updateDevice,
} from "../actions/deviceAction";
import { FetchOrganizationsData, fetchOrganizations } from "../actions/organizationAction";
import { FetchProjectsData, fetchProjects } from "../actions/projectAction";
import { wrapSliceWithCommonFunctions } from "../hoc/reducerWrapper";
import Device, { DeviceWithNotifications } from "../models/device";
import Organization from "../models/organization";
import Project from "../models/project";
import { CustomRootState, DefaultState } from "../store/state";

export interface DeviceState {
    device?: Device;
    devices: Array<Device>;
    devicesWithNotifications: Array<DeviceWithNotifications>;
    projects: Array<Project>;
    organizations: Array<Organization>;
}

type DeviceStateWithRootState = DeviceState & CustomRootState;

const InitialState: DeviceStateWithRootState = {
    ...DefaultState,
    devices: [],
    device: undefined,
    devicesWithNotifications: [],
    projects: [],
    organizations: []
};

const deviceSlice = wrapSliceWithCommonFunctions({
    name: "device",
    initialState: InitialState,
    reducers: {
        clearData: (state) => {
            state.device = undefined;
            state.devices = [];
        },
        clearStatus: (state: DeviceStateWithRootState) => {
            state.status = "idle";
            state.requestStatus = undefined;
            state.error = undefined;
        },
    },
    extraReducers: (builder) => {
        mapAsyncThunkToGlobalAction<DeviceStateWithRootState, FetchDevicesData>(
            builder,
            fetchDevicesAdmin,
            {
                pending: (state) => {
                    state.status = "loading";
                    state.devices = [];
                },
                fulfilled: (state, action) => {
                    state.status = "succeeded";
                    state.requestStatus = action.requestStatus;
                    state.devices = action.payload.data;
                },
                rejected: (state, action) => {
                    state.status = "failed";
                    state.error = action.error.message;
                    state.requestStatus = action.requestStatus;
                },
            }
        );

        mapAsyncThunkToGlobalAction<DeviceStateWithRootState, FetchDevicesWithNotificationsData>(
            builder,
            FetchDevicesWithNotifications,
            {
                pending: (state) => {
                    state.status = "loading";
                    state.devicesWithNotifications = [];
                },
                fulfilled: (state, action) => {
                    state.status = "succeeded";
                    state.requestStatus = action.requestStatus;
                    state.devicesWithNotifications = action.payload.data;
                },
                rejected: (state, action) => {
                    state.status = "failed";
                    state.error = action.error.message;
                    state.requestStatus = action.requestStatus;
                },
            }
        );

        mapAsyncThunkToGlobalAction<DeviceStateWithRootState, FetchDeviceData>(
            builder,
            fetchDevice,
            {
                pending: (state) => {
                    state.status = "loading";
                    state.devices = [];
                },
                fulfilled: (state, action) => {
                    state.status = "succeeded";
                    state.requestStatus = action.requestStatus;
                    state.device = action.payload.data;
                },
                rejected: (state, action) => {
                    state.status = "failed";
                    state.error = action.error.message;
                    state.requestStatus = action.requestStatus;
                },
            }
        );

        mapAsyncThunkToGlobalAction<DeviceStateWithRootState, CreateDeviceData>(
            builder,
            createDeviceAdmin,
            {
                pending: (state) => {
                    state.status = "loading";
                },
                fulfilled: (state, action) => {
                    state.status = "succeeded";
                    state.requestStatus = action.requestStatus;
                    state.device = action.payload.data;
                    state.devices.push(action.payload.data);
                },
                rejected: (state, action) => {
                    state.status = "failed";
                    state.error = action.error.message;
                    state.requestStatus = action.requestStatus;
                },
            }
        );

        mapAsyncThunkToGlobalAction<DeviceStateWithRootState, UpdateDeviceData>(
            builder,
            updateDevice,
            {
                pending: (state) => {
                    state.status = "loading";
                },
                fulfilled: (state, action) => {
                    state.status = "succeeded";
                    state.requestStatus = action.requestStatus;
                    state.device = action.payload.data;
                    state.devices = state.devices.map((device) => {
                        if (device.id === action.payload.data.id) {
                            device = action.payload.data;
                        }
                        return device;
                    });
                },
                rejected: (state, action) => {
                    state.status = "failed";
                    state.error = action.error.message;
                    state.requestStatus = action.requestStatus;
                },
            }
        );

        mapAsyncThunkToGlobalAction<DeviceStateWithRootState, UnlinkDeviceData>(
            builder,
            unlinkDeviceFromProject,
            {
                pending: (state) => {
                    state.status = "loading";
                },
                fulfilled: (state, action) => {
                    state.status = "succeeded";
                    state.requestStatus = action.requestStatus;
                    state.devices = state.devices.map((device) => {
                        if (device.id === action.payload.data.id) {
                            device = action.payload.data;
                        }
                        return device;
                    });
                },
                rejected: (state, action) => {
                    state.status = "failed";
                    state.error = action.error.message;
                    state.requestStatus = action.requestStatus;
                },
            }
        );
        mapAsyncThunkToGlobalAction<DeviceStateWithRootState, LinkDeviceData>(
            builder,
            linkDeviceToProject,
            {
                pending: (state) => {
                    state.status = "loading";
                },
                fulfilled: (state, action) => {
                    state.status = "succeeded";
                    state.requestStatus = action.requestStatus;

                    state.devices = state.devices.map((device) => {
                        if (device.id === action.payload.data.id) {
                            device = action.payload.data;
                        }
                        return device;
                    });
                },
                rejected: (state, action) => {
                    state.status = "failed";
                    state.error = action.error.message;
                    state.requestStatus = action.requestStatus;
                },
            }
        );

        mapAsyncThunkToGlobalAction<DeviceStateWithRootState, DeleteDeviceData>(
            builder,
            deleteDevice,
            {
                pending: (state) => {
                    state.status = "loading";
                },
                fulfilled: (state, action) => {
                    state.status = "succeeded";
                    state.requestStatus = action.requestStatus;
                    state.devices = state.devices.filter(
                        (device) => device.id !== action.payload.data
                    );
                    if (state.device?.id === action.payload.data) {
                        state.device = undefined;
                    }
                },
                rejected: (state, action) => {
                    state.status = "failed";
                    state.error = action.error.message;
                    state.requestStatus = action.requestStatus;
                },
            }
        );

        mapAsyncThunkToGlobalAction<
            DeviceStateWithRootState,
            FetchProjectsData
        >(builder, fetchProjects, {
            pending: (state) => {
                state.status = "loading";
                state.projects = [];
            },
            fulfilled: (state, action) => {
                state.status = "succeeded";
                state.requestStatus = action.requestStatus;
                state.projects = action.payload.data;
            },
            rejected: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.requestStatus = action.requestStatus;
            },
        });

        mapAsyncThunkToGlobalAction<
            DeviceStateWithRootState,
            FetchOrganizationsData
        >(builder, fetchOrganizations, {
            pending: (state) => {
                state.status = "loading";
            },
            fulfilled: (state, action) => {
                state.status = "succeeded";
                state.requestStatus = action.requestStatus;
                state.organizations = action.payload.data;
            },
            rejected: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.requestStatus = action.requestStatus;
            },
        });
    },
});

export const { clearData, clearStatus } = deviceSlice.actions;

export default deviceSlice.reducer;
