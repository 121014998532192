import { createColumnHelper } from "@tanstack/react-table";
import useAppDispatch from "../../hooks/useAppDispatch";
import User from "../../models/user";
import { useSelector } from "react-redux";
import { Box, Button, Heading, VStack } from "@chakra-ui/react";
import { RootState } from "../../store";
import { useCallback, useMemo } from "react";
import { OrganizationFull } from "../../models/organization";
import { DeleteIcon } from "@chakra-ui/icons";
import { DataTable } from "../../components/shared/table/DataTable";
import {
    deleteOrganizationAdmin,
    deleteOrganizationUser,
    fetchNonAdminUsers,
} from "../../actions/organizationAction";
import AddAdminModal from "./AddAdminModal";
import AddUserModal from "./AddUserModal";
import { Can } from "../../context/abilityContext";
import useCustomAbility from "../../hooks/useCustomAbility";

const userColumnHelper = createColumnHelper<User>();

type OrganizationAdminOrUserDetailsProps = {
    organization: OrganizationFull;
    id: string;
    isAdmin: boolean;
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
};

const OrganizationAdminOrUserDetails: React.FC<
    OrganizationAdminOrUserDetailsProps
> = ({ organization, id, isAdmin, isOpen, onOpen, onClose }) => {
    const dispatch = useAppDispatch();
    const ability = useCustomAbility();
    const nonAdminUsers = useSelector(
        (state: RootState) => state.organization.nonAdminUsers
    );

    const handleUserDelete = useCallback(
        (user: User) => {
            if (id && user.id) {
                if (isAdmin) {
                    dispatch(
                        deleteOrganizationAdmin({ id: id, userId: user.id })
                    );
                } else {
                    dispatch(
                        deleteOrganizationUser({ id: id, userId: user.id })
                    );
                }
            }
        },
        [dispatch, id, isAdmin, ability, organization]
    );

    const handleAddModal = useCallback(() => {
        if (isAdmin) {
            if (id) {
                dispatch(fetchNonAdminUsers({ id: id }));
            }
        }
        onOpen();
    }, [dispatch, id, isAdmin, onOpen]);

    const userColumns = useMemo(
        () => [
            userColumnHelper.accessor("name", {
                cell: (info) => info.getValue(),
                header: "Name",
            }),
            userColumnHelper.accessor("username", {
                cell: (info) => info.getValue(),
                header: "Username",
            }),
            userColumnHelper.accessor("email", {
                cell: (info) => info.getValue(),
                header: "Email",
            }),
            userColumnHelper.display({
                id: "delete",
                cell: (info) => (
                    <Can I="Delete" a="Organization_User">
                        <Box>
                            <Button
                                leftIcon={<DeleteIcon />}
                                colorScheme="red"
                                variant="outline"
                                onClick={() => handleUserDelete(info.row.original)}
                            >
                                Delete
                            </Button>
                        </Box>
                    </Can>
                ),
            }),
        ],
        [handleUserDelete]
    );

    return (
        <VStack spacing={8} alignItems="start">
            <Box mt={4}>
                {isAdmin && <Heading size="md">Admins</Heading>}
                {!isAdmin && <Heading size="md">Users</Heading>}
            </Box>
            <Box width="100%" overflowX="auto">
                {isAdmin &&
                    organization?.admins &&
                    organization.admins.length > 0 && (
                        <DataTable
                            columns={userColumns}
                            data={organization.admins}
                        />
                    )}

                {!isAdmin &&
                    organization?.users &&
                    organization.users.length > 0 && (
                        <DataTable
                            columns={userColumns}
                            data={organization.users}
                        />
                    )}
            </Box>
            <Box mt={2}>
                <Can I="Add" a="Organization_User">
                    <Button
                        colorScheme="blue"
                        variant="outline"
                        onClick={() => handleAddModal()}
                    >
                        {isAdmin && "Add admin to organization"}
                        {!isAdmin && "Add user to organization"}
                    </Button>
                </Can>
                {id && nonAdminUsers && isAdmin && ability.can("Add", "Organization_Admin") && (
                    <AddAdminModal
                        organizationId={id}
                        users={nonAdminUsers}
                        isOpen={isOpen}
                        onClose={onClose}
                    />
                )}
                {id && nonAdminUsers && !isAdmin && ability.can("Add", "Organization_User") && (
                    <AddUserModal
                        organizationId={id}
                        isOpen={isOpen}
                        onClose={onClose}
                    />
                )}
            </Box>
        </VStack>
    );
};

export default OrganizationAdminOrUserDetails;
