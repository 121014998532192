import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { useNavigate } from 'react-router-dom';
import DeviceMaps from './device/DeviceMaps';

const Home: React.FC = () => {
  const user = useSelector((state: RootState) => state.auth.userData.user);
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) {
      navigate('/auth/login');
    }
  }, [user, navigate]);

  return (
    <Box>
      {user && (<DeviceMaps />)}
    </Box>
  );
};

export default Home;
