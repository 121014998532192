import Joi from "joi";
import Form, { FormFieldProps } from "../../components/form";
import { useCallback, useEffect, useMemo } from "react";
import useAppDispatch from "../../hooks/useAppDispatch";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { fetchOrganizations } from "../../actions/organizationAction";
import { createDeviceAdmin } from "../../actions/deviceAction";
import Device from "../../models/device";
import { useNavigate } from "react-router-dom";
import useStateHandling from "../../hooks/useStateHandling";
import { Box, Heading } from "@chakra-ui/react";
import StatusHandler from "../../components/shared/StatusHandler";
import useCustomAbility from "../../hooks/useCustomAbility";

const deviceSchema = Joi.object({
    deviceHardwareId: Joi.string().required(),
    organizationId: Joi.string().required(),
});

const DeviceCreate: React.FC = () => {
    const { status, error } = useStateHandling("organization");
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const ability = useCustomAbility();
    const organizations = useSelector(
        (state: RootState) => state.organization.organizations
    );
    const methods = useForm({
        resolver: joiResolver(deviceSchema),
    });

    useEffect(() => {
        if (!ability.can("Create", "Device")) {
            navigate("/");
        } else {
            dispatch(fetchOrganizations());
        }
    }, [dispatch, ability, navigate]);

    const deviceFields: FormFieldProps[] = useMemo(
        () => [
            {
                name: "deviceHardwareId",
                label: "Hardware ID",
                type: "text",
                required: true,
                placeHolder: "Enter hardware ID",
            },
            {
                name: "organizationId",
                label: "Organization",
                element: "select",
                required: true,
                options: organizations.map((organization) => ({
                    value: organization.id as string,
                    label: organization.name as string,
                })),
            },
        ], [organizations]);

    const handleFormSubmit = useCallback(
        (data: Device) => {
            dispatch(createDeviceAdmin(data));
            navigate("/devices");
        },
        [dispatch, navigate]
    );

    return (
        <Box>
            <StatusHandler status={status} error={error}>
                <Heading>Create Device</Heading>
                <Box mt={6} width="100%" overflowX="auto">
                    <Form
                        fields={deviceFields}
                        methods={methods}
                        onSubmit={handleFormSubmit}
                        submitText="Create device"
                    />
                </Box>
            </StatusHandler>
        </Box>

    );
}

export default DeviceCreate;