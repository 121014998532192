import { logoutUser, updatePermissions, updateTokens } from "../reducers/authReducer";
import ResponseError from "../types/ResponseError";

const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME || 'cctv-service-bucket/';
export const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT || 'http://localhost:5041';
export const CLOUD_URI = process.env.REACT_APP_CLOUD_URI || 'https://storage.googleapis.com/' + BUCKET_NAME;
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || 'AIzaSyCAa0YWGSmX8QrwvaEPvSgYIb8MH2GQYeI';

type Request = {
    thunkApi: any;
    url: string;
    method: string;
    data?: any;
    headers?: object;
};

export type AuthHeader = {
    Authorization?: string;
    "x-refresh-token"?: string;
};

let isRefreshing = false;
let refreshSubscribers: Array<() => void> = [];

const subscribeTokenRefresh = (callback: () => void) => {
    refreshSubscribers.push(callback);
};

const onRefreshed = () => {
    refreshSubscribers.forEach((callback) => callback());
    refreshSubscribers = [];
};

const sendHttpRequest = async (
    url: string,
    method: string,
    data: any,
    headers: any = {}
) => {
    const options: RequestInit = {
        method,
        headers: {
            ...headers,
        },
    };

    if (data instanceof FormData) {
        options.body = data;
    } else if (data) {
        options.headers = {
            "Content-Type": "application/json",
            ...options.headers,
        };
        options.body = JSON.stringify(data);
    }

    return await fetch(`${API_BASE_URL}/${url}`, options);
};

const refreshAndRetry = async (
    thunkApi: any,
    url: string,
    method: string,
    data: any,
    headers: any = {}
) => {
    const { token, refreshToken } = await thunkApi.getState().auth.userData;
    
    if (isRefreshing) {
        return new Promise((resolve) => {
            subscribeTokenRefresh(async () => {
                const { token: newToken } = await thunkApi.getState().auth.userData;
                headers.Authorization = `Bearer ${newToken}`;
                resolve(sendHttpRequest(url, method, data, headers));
            });
        });
    }

    if (token && refreshToken) {
        isRefreshing = true;
        const refreshResponse = await fetch(
            `${API_BASE_URL}/api/auth/refresh-token`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    ...headers,
                },
                body: JSON.stringify({ refreshToken: refreshToken }),
            }
        );

        if (refreshResponse.status === 200) {
            const body = await refreshResponse.json();
            const newToken = body.data.token;
            const newRefreshToken = body.data.refreshToken;
            const permissions = body.data.permissions;

            await thunkApi.dispatch(
                updateTokens({ token: newToken, refreshToken: newRefreshToken })
            );

            await thunkApi.dispatch(
                updatePermissions(permissions)
            );

            headers.Authorization = `Bearer ${newToken}`;
            isRefreshing = false;
            onRefreshed();

            return await sendHttpRequest(url, method, data, headers);
        }

        isRefreshing = false;
        return await thunkApi.dispatch(logoutUser());
    }

    await thunkApi.dispatch(logoutUser());
    return new Response(null, { status: 401, statusText: "Please sign in again!" });
};

const httpRequest = async <T>({
    thunkApi,
    url,
    method,
    data = null,
    headers = {},
}: Request) => {
    let response = await sendHttpRequest(url, method, data, headers);

    if (response.status === 401) {
        response = await refreshAndRetry(thunkApi, url, method, data, headers);
    }

    if (!response.ok) {
        let errorMessage: string;

        try {
            const body = await response.json();

            errorMessage =
                body?.message ?? "An error occurred. Please try again later.";
        } catch (e: any) {
            errorMessage = `An error occurred: ${response.statusText}`;
        }

        throw new ResponseError(errorMessage);
    }

    return (await response.json()) as T;
};

const get = <T>(
    thunkApi: any,
    url: string,
    headers: object | undefined = undefined
) =>
    httpRequest<T>({
        thunkApi: thunkApi,
        url: url,
        method: "GET",
        data: null,
        headers: headers,
    });

const post = <T>(
    thunkApi: any,
    url: string,
    data: object | undefined = undefined,
    headers: object | undefined = undefined
) =>
    httpRequest<T>({
        thunkApi: thunkApi,
        url: url,
        method: "POST",
        data: data,
        headers: headers,
    });

const put = <T>(
    thunkApi: any,
    url: string,
    data: object | undefined = undefined,
    headers: object | undefined = undefined
) =>
    httpRequest<T>({
        thunkApi: thunkApi,
        url: url,
        method: "PUT",
        data: data,
        headers: headers,
    });

const deleteRequest = <T>(
    thunkApi: any,
    url: string,
    headers: object | undefined = undefined
) =>
    httpRequest<T>({
        thunkApi: thunkApi,
        url: url,
        method: "DELETE",
        data: null,
        headers: headers,
    });

const postMultiPart = <T>(
    thunkApi: any,
    url: string,
    data: FormData | undefined = undefined,
    headers: object | undefined = undefined
) =>
    httpRequest<T>({
        thunkApi: thunkApi,
        url: url,
        method: "POST",
        data: data,
        headers: headers,
    });

const putMultiPart = <T>(
    thunkApi: any,
    url: string,
    data: FormData | undefined = undefined,
    headers: object | undefined = undefined
) =>
    httpRequest<T>({
        thunkApi: thunkApi,
        url: url,
        method: "PUT",
        data: data,
        headers: headers,
    });

const createAuthClient = (thunkApi: any) => {
    const { token } = thunkApi.getState().auth.userData;
    let authHeader: AuthHeader = {};

    if (token) {
        authHeader = {
            Authorization: `Bearer ${token}`,
        };
    }

    const getWithAuth = <T>(
        url: string,
        headers: object | undefined = undefined
    ) => get<T>(thunkApi, url, { ...headers, ...authHeader });
    const postWithAuth = <T>(
        url: string,
        data: object | undefined = undefined,
        headers: object | undefined = undefined
    ) => post<T>(thunkApi, url, data, { ...headers, ...authHeader });
    const putWithAuth = <T>(
        url: string,
        data: object | undefined = undefined,
        headers: object | undefined = undefined
    ) => put<T>(thunkApi, url, data, { ...headers, ...authHeader });
    const deleteWithAuth = <T>(
        url: string,
        headers: object | undefined = undefined
    ) => deleteRequest<T>(thunkApi, url, { ...headers, ...authHeader });
    const postMultiPartWithAuth = <T>(
        url: string,
        data: FormData | undefined = undefined,
        headers: object | undefined = undefined
    ) =>
        postMultiPart<T>(thunkApi, url, data, {
            ...authHeader,
            ...headers,
        });

    const putMultiPartWithAuth = <T>(
        url: string,
        data: FormData | undefined = undefined,
        headers: object | undefined = undefined
    ) =>
        putMultiPart<T>(thunkApi, url, data, {
            ...authHeader,
            ...headers,
        });

    return {
        get: getWithAuth,
        post: postWithAuth,
        put: putWithAuth,
        deleteRequest: deleteWithAuth,
        postMultiPart: postMultiPartWithAuth,
        putMultiPart: putMultiPartWithAuth,
    };
};

export default createAuthClient;
