import Joi from "joi";
import Form from "../../components/form";
import useStateHandling from "../../hooks/useStateHandling";
import { useNavigate, useParams } from "react-router-dom";
import useAppDispatch from "../../hooks/useAppDispatch";
import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import Project from "../../models/project";
import StatusHandler from "../../components/shared/StatusHandler";
import { Box, Heading, VStack } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { fetchProject, updateProject } from "../../actions/projectAction";
import useCustomAbility from "../../hooks/useCustomAbility";

const projectSchema = Joi.object({
    name: Joi.string().required(),
});

type ProjectEditParams = {
    id: string;
};

const ProjectEdit: React.FC = () => {
    const { status, error } = useStateHandling("project");
    const { id } = useParams<ProjectEditParams>();
    const dispatch = useAppDispatch();
    const ability = useCustomAbility();
    const navigate = useNavigate();
    const project = useSelector(
        (state: RootState) => state.project.projectFull
    );

    useEffect(() => {
        if (!ability.can("Update", "Project")) {
            navigate("/");
        } else if (id) {
            dispatch(fetchProject({ id: id }));
        }
    }, [dispatch, id]);

    const methods = useForm({
        resolver: joiResolver(projectSchema),
    });

    const projectFields = useMemo(
        () => [
            {
                name: "name",
                label: "Name",
                type: "text",
                required: true,
                value: project?.project.name as string | undefined,
            },
        ],
        [project]
    );

    const handleFormSubmit = useCallback(
        (data: Project) => {
            if (id) {
                dispatch(
                    updateProject({
                        id: id,
                        name: data.name,
                    })
                );
            }
            navigate("/projects");
        },
        [dispatch, id, navigate]
    );

    return (
        <Box>
            <StatusHandler status={status} error={error}>
                <Heading>Edit project</Heading>
                <VStack spacing={4} mt={8}>
                    <Box mt={6} width="100%" overflowX="auto">
                        <Form
                            fields={projectFields}
                            onSubmit={handleFormSubmit}
                            methods={methods}
                            submitText="Save"
                        />
                    </Box>
                </VStack>
            </StatusHandler>
        </Box>
    );
}

export default ProjectEdit;
