import { mapAsyncThunkToGlobalAction } from "../actions";
import { DeleteDeviceData, UnlinkDeviceData, deleteDevice, unlinkDeviceFromProject } from "../actions/deviceAction";
import {
    AddAdminOrUserToProjectData,
    DeleteProjectAdminOrUserData,
    DeleteProjectData,
    FetchNonAdminOrProjectUsersData,
    FetchProjectByOrganizationData,
    FetchProjectData,
    FetchProjectsData,
    UpdateProjectData,
    addAdminToProject,
    addUserToProject,
    deleteProject,
    deleteProjectAdmin,
    deleteProjectUser,
    fetchProject,
    fetchProjectNonAdminUsers,
    fetchProjectNonUsers,
    fetchProjects,
    fetchProjectsByOrganization,
    updateProject,
} from "../actions/projectAction";
import { wrapSliceWithCommonFunctions } from "../hoc/reducerWrapper";
import Project, { ProjectFull } from "../models/project";
import User from "../models/user";
import { CustomRootState, DefaultState } from "../store/state";

export interface ProjectState {
    projectFull?: ProjectFull;
    projects: Array<Project>;
    project?: Project;
    nonAdminUsers: Array<User>;
    nonProjectUsers: Array<User>;
}

type ProjectStateWithRootState = ProjectState & CustomRootState;

const InitialState: ProjectStateWithRootState = {
    ...DefaultState,
    projects: [],
    project: undefined,
    projectFull: undefined,
    nonAdminUsers: [],
    nonProjectUsers: [],
};

const projectSlice = wrapSliceWithCommonFunctions({
    name: "project",
    initialState: InitialState,
    reducers: {
        clearData: (state) => {
            state.project = undefined;
            state.projects = [];
            state.projectFull = undefined;
            state.nonAdminUsers = [];
            state.nonProjectUsers = [];
        },
    },
    extraReducers: (builder) => {
        mapAsyncThunkToGlobalAction<
            ProjectStateWithRootState,
            FetchProjectsData
        >(builder, fetchProjects, {
            pending: (state) => {
                state.status = "loading";
                state.projects = [];
            },
            fulfilled: (state, action) => {
                state.status = "succeeded";
                state.requestStatus = action.requestStatus;
                state.projects = action.payload.data;
            },
            rejected: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.requestStatus = action.requestStatus;
            },
        });

        mapAsyncThunkToGlobalAction<
            ProjectStateWithRootState,
            FetchProjectData
        >(builder, fetchProject, {
            pending: (state) => {
                state.status = "loading";
            },
            fulfilled: (state, action) => {
                state.status = "succeeded";
                state.requestStatus = action.requestStatus;
                state.projectFull = action.payload.data;
            },
            rejected: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.requestStatus = action.requestStatus;
            },
        });

        mapAsyncThunkToGlobalAction<
            ProjectStateWithRootState,
            UpdateProjectData
        >(builder, updateProject, {
            pending: (state) => {
                state.status = "loading";
            },
            fulfilled: (state, action) => {
                state.status = "succeeded";
                state.requestStatus = action.requestStatus;
                state.project = action.payload.data;
                state.projects = state.projects.map((project) => {
                    if (project.id === action.payload.data.id) {
                        return action.payload.data;
                    }
                    return project;
                });
            },
            rejected: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.requestStatus = action.requestStatus;
            },
        });

        mapAsyncThunkToGlobalAction<
            ProjectStateWithRootState,
            DeleteProjectData
        >(builder, deleteProject, {
            pending: (state) => {
                state.status = "loading";
            },
            fulfilled: (state, action) => {
                state.status = "succeeded";
                state.requestStatus = action.requestStatus;
                state.projects = state.projects.filter(
                    (project) => project.id !== action.payload.data
                );
                state.project = undefined;
                state.projectFull = undefined;
            },
            rejected: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.requestStatus = action.requestStatus;
            },
        });

        mapAsyncThunkToGlobalAction<
            ProjectStateWithRootState,
            FetchNonAdminOrProjectUsersData
        >(builder, fetchProjectNonAdminUsers, {
            pending: (state) => {
                state.status = "loading";
                state.nonAdminUsers = [];
            },
            fulfilled: (state, action) => {
                state.status = "succeeded";
                state.requestStatus = action.requestStatus;
                state.nonAdminUsers = action.payload.data;
            },
            rejected: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.requestStatus = action.requestStatus;
            },
        });

        mapAsyncThunkToGlobalAction<
            ProjectStateWithRootState,
            FetchNonAdminOrProjectUsersData
        >(builder, fetchProjectNonUsers, {
            pending: (state) => {
                state.status = "loading";
                state.nonProjectUsers = [];
            },
            fulfilled: (state, action) => {
                state.status = "succeeded";
                state.requestStatus = action.requestStatus;
                state.nonProjectUsers = action.payload.data;
            },
            rejected: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.requestStatus = action.requestStatus;
            },
        });

        mapAsyncThunkToGlobalAction<
            ProjectStateWithRootState,
            DeleteProjectAdminOrUserData
        >(builder, deleteProjectAdmin, {
            pending: (state) => {
                state.status = "loading";
            },
            fulfilled: (state, action) => {
                state.status = "succeeded";
                state.requestStatus = action.requestStatus;
                const deletedAdmin = state.projectFull?.admins.find(
                    (admin) => admin.id === action.payload.data
                );
                if (state.nonAdminUsers) {
                    state.nonAdminUsers = [
                        ...state.nonAdminUsers,
                        deletedAdmin!,
                    ];
                }

                if (state.projectFull) {
                    state.projectFull.admins = state.projectFull.admins.filter(
                        (admin) => admin.id !== action.payload.data
                    );

                    state.projectFull.users = [
                        ...(state.projectFull.users ?? []),
                        deletedAdmin!,
                    ];
                }

                if (state.project) {
                    state.project.adminIds = state.project.adminIds.filter(
                        (adminId) => adminId !== action.payload.data
                    );

                    state.project.userIds = [
                        ...(state.project.userIds ?? []),
                        deletedAdmin!.id,
                    ];
                }
            },
            rejected: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.requestStatus = action.requestStatus;
            },
        });

        mapAsyncThunkToGlobalAction<
            ProjectStateWithRootState,
            DeleteProjectAdminOrUserData
        >(builder, deleteProjectUser, {
            pending: (state) => {
                state.status = "loading";
            },
            fulfilled: (state, action) => {
                state.status = "succeeded";
                state.requestStatus = action.requestStatus;

                if (state.nonProjectUsers) {
                    const deletedUser = state.projectFull?.users.find(
                        (user) => user.id === action.payload.data
                    );

                    state.nonProjectUsers = [
                        ...state.nonProjectUsers,
                        deletedUser!,
                    ];
                }

                if (state.projectFull) {
                    state.projectFull.users = state.projectFull.users.filter(
                        (user) => user.id !== action.payload.data
                    );
                }

                if (state.project) {
                    state.project.userIds = state.project.userIds.filter(
                        (userId) => userId !== action.payload.data
                    );
                }
            },
            rejected: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.requestStatus = action.requestStatus;
            },
        });

        mapAsyncThunkToGlobalAction<
            ProjectStateWithRootState,
            AddAdminOrUserToProjectData
        >(builder, addAdminToProject, {
            pending: (state) => {
                state.status = "loading";
            },
            fulfilled: (state, action) => {
                state.status = "succeeded";
                state.requestStatus = action.requestStatus;
                const addedUser = action.payload.data;
                state.nonAdminUsers = state.nonAdminUsers?.filter(
                    (user) => user.id !== addedUser.id
                );
                if (state.projectFull) {
                    state.projectFull.admins = [
                        ...(state.projectFull.admins ?? []),
                        addedUser!,
                    ];

                    state.projectFull.users = state.projectFull.users.filter(
                        (user) => user.id !== addedUser.id
                    );
                }
                if (state.project) {
                    state.project.adminIds = [
                        ...(state.project.adminIds ?? []),
                        addedUser!.id,
                    ];

                    state.project.userIds = state.project.userIds.filter(
                        (userId) => userId !== addedUser.id
                    );
                }
            },
            rejected: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.requestStatus = action.requestStatus;
            },
        });

        mapAsyncThunkToGlobalAction<
            ProjectStateWithRootState,
            AddAdminOrUserToProjectData
        >(builder, addUserToProject, {
            pending: (state) => {
                state.status = "loading";
            },
            fulfilled: (state, action) => {
                state.status = "succeeded";
                state.requestStatus = action.requestStatus;
                const addedUser = action.payload.data;
                state.nonProjectUsers = state.nonProjectUsers?.filter(
                    (user) => user.id !== action.payload.data.id
                );
                if (state.projectFull) {
                    state.projectFull.users = [
                        ...(state.projectFull.users ?? []),
                        addedUser!,
                    ];
                }
                if (state.project) {
                    state.project.userIds = [
                        ...(state.project.userIds ?? []),
                        addedUser!.id,
                    ];
                }
            },
            rejected: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.requestStatus = action.requestStatus;
            },
        });

        mapAsyncThunkToGlobalAction<
            ProjectStateWithRootState,
            FetchProjectByOrganizationData
        >(builder, fetchProjectsByOrganization, {
            pending: (state) => {
                state.status = "loading";
                state.projects = [];
            },
            fulfilled: (state, action) => {
                state.status = "succeeded";
                state.requestStatus = action.requestStatus;
                state.projects = action.payload.data;
            },
            rejected: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.requestStatus = action.requestStatus;
            },
        });

        mapAsyncThunkToGlobalAction<
            ProjectStateWithRootState,
            UnlinkDeviceData
        >(builder, unlinkDeviceFromProject, {
            pending: (state) => {
                state.status = "loading";
            },
            fulfilled: (state, action) => {
                state.status = "succeeded";
                state.requestStatus = action.requestStatus;
                if (state.projectFull) {
                    state.projectFull.devices = state.projectFull.devices.filter(
                        (device) => device.id !== action.payload.data.id
                    );
                }

                if (state.project) {
                    state.project.deviceIds =
                        state.project?.deviceIds?.filter(
                            (deviceId) => deviceId !== action.payload.data.id
                        );
                }
            },
            rejected: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.requestStatus = action.requestStatus;
            },
        });

        mapAsyncThunkToGlobalAction<
            ProjectStateWithRootState,
            DeleteDeviceData
        >(builder, deleteDevice, {
            pending: (state) => {
                state.status = "loading";
            },
            fulfilled: (state, action) => {
                state.status = "succeeded";
                state.requestStatus = action.requestStatus;
                if (state.projectFull) {
                    state.projectFull.devices = state.projectFull.devices.filter(
                        (device) => device.id !== action.payload.data
                    );
                }

                if (state.project) {
                    state.project.deviceIds =
                        state.project?.deviceIds?.filter(
                            (deviceId) => deviceId !== action.payload.data
                        );
                }
            },
            rejected: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.requestStatus = action.requestStatus;
            },
        });
    },
});

export const { clearData } = projectSlice.actions;

export default projectSlice.reducer;
