import Joi from "joi";
import Project from "../../models/project";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useCallback, useMemo } from "react";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import useAppDispatch from "../../hooks/useAppDispatch";
import Form, { FormFieldProps } from "../../components/form";
import { createProject } from "../../actions/projectAction";
import { useNavigate } from "react-router-dom";

interface AddProjectModalProps {
    organizationId: string;
    isOpen: boolean;
    onClose: () => void;
}

const addProjectSchema = Joi.object({
    name: Joi.string().required(),
});

const AddProjectModal: React.FC<AddProjectModalProps> = ({
    organizationId,
    isOpen,
    onClose,
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const addProjectFields: FormFieldProps[] = useMemo(
        () => [
            {
                name: "name",
                label: "Name",
                type: "text",
                required: true,
                placeHolder: "Enter name",
            }
        ],
        []
    );

    const methods = useForm({
        resolver: joiResolver(addProjectSchema),
    });

    const handleOnSubmit = useCallback(
        (data: Project) => {
            if (data && organizationId) {
                dispatch(createProject({
                    organizationId: organizationId,
                    name: data.name,
                }));
                navigate(`/organizations/${organizationId}`);
                onClose();
            }
        },
        [organizationId, dispatch, navigate, onClose]
    );

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Create project</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Form
                        fields={addProjectFields}
                        onSubmit={handleOnSubmit}
                        methods={methods}
                        submitText="Create project"
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default AddProjectModal;