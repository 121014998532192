import useAppDispatch from "../../hooks/useAppDispatch";
import Device from "../../models/device";
import Project from "../../models/project";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { Modal, ModalContent, ModalHeader, ModalCloseButton, ModalBody } from "@chakra-ui/react";
import Joi from "joi";
import Form, { FormFieldProps } from "../../components/form";
import { linkDeviceToProject } from "../../actions/deviceAction";

interface LinkDeviceToProjectModalProps {
    device: Device;
    isOpen: boolean;
    onClose: () => void;
    projects: Project[];
}

const linkDeviceToProjectSchema = Joi.object({
    project: Joi.string().required(),
});

const LinkDeviceToProjectModal: React.FC<LinkDeviceToProjectModalProps> = ({
    device,
    isOpen,
    onClose,
    projects,
}) => {
    const dispatch = useAppDispatch();

    const linkDeviceToProjectFields: FormFieldProps[] = useMemo(
        () => [
            {
                name: "project",
                label: "Projects",
                element: "select",
                required: true,
                options: projects.map((project) => ({
                    value: project.id as string,
                    label: project.name as string,
                })),
            },
        ],
        [projects]
    );

    const methods = useForm({
        resolver: joiResolver(linkDeviceToProjectSchema),
    });

    const handleOnSubmit = useCallback(
        (data: any) => {
            if (device.id && data.project) {
                dispatch(linkDeviceToProject({
                    deviceId: device.id,
                    projectId: data.project,
                })
                );
            }
        },
        [device, dispatch]
    );

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalContent>
                <ModalHeader textAlign="center">Link device to project</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Form
                        fields={linkDeviceToProjectFields}
                        methods={methods}
                        onSubmit={handleOnSubmit}
                        submitText="Link device to project"
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default LinkDeviceToProjectModal;