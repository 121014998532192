import { createColumnHelper } from "@tanstack/react-table";
import Project from "../../models/project";
import { OrganizationFull } from "../../models/organization";
import { Box, Button, Flex, Heading, VStack } from "@chakra-ui/react";
import { DataTable } from "../../components/shared/table/DataTable";
import { DeleteIcon, InfoIcon } from "@chakra-ui/icons";
import { useCallback, useMemo } from "react";
import useAppDispatch from "../../hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import { deleteProject } from "../../actions/projectAction";
import AddProjectModal from "./AddProjectModal";
import { Can } from "../../context/abilityContext";

const projectColumnHelper = createColumnHelper<Project>();

type OrganizationProjectDetailsProps = {
    organization: OrganizationFull;
    id: string;
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
};

const OrganizationProjectDetails: React.FC<OrganizationProjectDetailsProps> = ({
    organization,
    id,
    isOpen,
    onOpen,
    onClose,
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleProjectDelete = useCallback(
        (project: Project) => {
            if (id && project.id) {
                dispatch(deleteProject({ projectId: project.id }));
            }
        },
        [dispatch, id]
    );

    const handleAddProjectModal = useCallback(() => {
        onOpen();
    }, [onOpen]);

    const handleDetailsClick = useCallback(
        (project: Project) => {
            navigate(`/projects/${project.id}`);
        },
        [navigate]
    );

    const projectColumns = useMemo(
        () => [
            projectColumnHelper.accessor("name", {
                cell: (info) => info.getValue(),
                header: "Name",
            }),
            projectColumnHelper.display({
                id: "buttons",
                cell: (info) => (
                    <Flex justifyContent="flex-end">
                        <Can I="Get" a="Project">
                            <Button
                                leftIcon={<InfoIcon />}
                                colorScheme="gray"
                                variant="outline"
                                onClick={() =>
                                    handleDetailsClick(info.row.original)
                                }
                            >
                                Details
                            </Button>
                        </Can>
                        <Can I="Delete" a="Project">
                            <Button
                                ml={10}
                                mr={20}
                                leftIcon={<DeleteIcon />}
                                colorScheme="red"
                                variant="outline"
                                onClick={() =>
                                    handleProjectDelete(info.row.original)
                                }
                            >
                                Delete
                            </Button>
                        </Can>
                    </Flex>
                ),
            }),
        ],
        [handleDetailsClick, handleProjectDelete]
    );

    return (
        <VStack spacing={8} alignItems="start">
            <Box mt={4}>
                <Heading size="md">Projects</Heading>
            </Box>
            <Box width="100%" overflowX="auto">
                {organization?.projects && organization.projects.length > 0 && (
                    <DataTable
                        columns={projectColumns}
                        data={organization.projects}
                    />
                )}
            </Box>
            <Can I="Create" a="Project">
                <Button
                    colorScheme="blue"
                    variant="outline"
                    onClick={() => handleAddProjectModal()}
                >
                    Create project
                </Button>
                {id && (
                    <AddProjectModal
                        isOpen={isOpen}
                        onClose={onClose}
                        organizationId={id}
                    />
                )}
            </Can>
        </VStack>
    );
};

export default OrganizationProjectDetails;
