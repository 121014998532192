import { createColumnHelper } from "@tanstack/react-table";
import { useCallback, useMemo, useState } from "react";
import { Box, Button, Heading, VStack, useDisclosure } from "@chakra-ui/react";
import Notification from "../../models/notification";
import { DataTable } from "../../components/shared/table/DataTable";
import { BiVideo } from "react-icons/bi";
import VideoModal from "./VideoModal";
import useAppDispatch from "../../hooks/useAppDispatch";
import { deleteNotification } from "../../actions/notificationAction";
import useCustomAbility from "../../hooks/useCustomAbility";
import { Can } from "../../context/abilityContext";

const columnHelper = createColumnHelper<Notification>();

type NotificationListProps = {
    notifications: Notification[];
};

const NotificationList: React.FC<NotificationListProps> = ({ notifications }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const dispatch = useAppDispatch();
    const ability = useCustomAbility();
    const [selectedNotification, setSelectedNotification] =
        useState<Notification | null>(null);

    const handleVideoClick = useCallback(
        (notification: Notification) => {
            setSelectedNotification(notification);
            onOpen();
        },
        [onOpen]
    );

    const handleDeleteClick = useCallback(
        (notification: Notification) => {
            dispatch(deleteNotification({ notificationId: notification.id }));
        },
        [dispatch]
    );

    const columns = useMemo(
        () => [
            columnHelper.accessor("deviceHardwareId", {
                cell: (info) => info.getValue(),
                header: "Device hardware id",
            }),
            columnHelper.accessor("timestamp", {
                cell: (info) => {
                    const dateValue = info.getValue();
                    const formattedDate = dateValue
                        ? new Date(dateValue).toLocaleString("ro-RO", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                            timeZoneName: "short",
                        })
                        : "";

                    return formattedDate;
                },
                header: "Timestamp",
            }),
            columnHelper.accessor("description", {
                cell: (info) => info.row.original.description ?? "",
                header: "Description",
            }),
            columnHelper.display({
                id: "buttons",
                cell: (info) => (
                    <Box>
                        {info.row.original.cloudPath && ability.can("Get", "Video") && (
                            <Button
                                leftIcon={<BiVideo />}
                                colorScheme="gray"
                                variant="outline"
                                onClick={() =>
                                    handleVideoClick(info.row.original)
                                }
                            >
                                Video
                            </Button>
                        )}
                        <Can I="Delete" a="Notification">
                            <Button
                                ml={10}
                                colorScheme="red"
                                variant="outline"
                                onClick={() =>
                                    handleDeleteClick(info.row.original)
                                }
                            >
                                Delete
                            </Button>
                        </Can>
                    </Box>
                ),
            }),
        ],
        [handleDeleteClick, handleVideoClick]
    );

    return (
        <VStack spacing={8} alignItems="start">
            <Heading>Notifications</Heading>
            <Box width="100%" overflowX="auto">
                <DataTable data={notifications} columns={columns} />
                {selectedNotification && ability.can("Get", "Video") && (
                    <VideoModal
                        notification={selectedNotification}
                        isOpen={isOpen}
                        onClose={onClose}
                    />
                )}
            </Box>
        </VStack>
    );
};

export default NotificationList;
