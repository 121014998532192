import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader } from "@chakra-ui/react";
import Device from "../../models/device";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { GOOGLE_MAPS_API_KEY } from "../../services/createAuthClient";
import mapsStyles from "../../utils/mapsUtils";

interface DeviceMapsModalProps {
    device: Device;
    isOpen: boolean;
    onClose: () => void;
}

const DeviceMapsModal: React.FC<DeviceMapsModalProps> = ({ device, onClose, isOpen}) => {

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    });

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalContent>
                <ModalHeader>Device maps</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {isLoaded && (
                        <GoogleMap
                            mapContainerStyle={{ height: "60vh", width: "100%" }}
                            center={{
                                lat: device.geolocationLatitude || 45.39,
                                lng: device.geolocationLongitude || 25.36
                            }}
                            zoom={14}
                            options={{
                                styles: mapsStyles
                            }}
                        >
                            {device.geolocationLatitude && device.geolocationLongitude && ( <MarkerF
                                position={{
                                    lat: device.geolocationLatitude,
                                    lng: device.geolocationLongitude
                                }}
                            />)}
                        </GoogleMap>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default DeviceMapsModal;