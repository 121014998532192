import { useSelector } from "react-redux";
import useAppDispatch from "../../hooks/useAppDispatch";
import useStateHandling from "../../hooks/useStateHandling";
import { RootState } from "../../store";
import { FetchDevicesWithNotifications } from "../../actions/deviceAction";
import { useCallback, useEffect, useState } from "react";
import StatusHandler from "../../components/shared/StatusHandler";
import { Box, Button, Collapse, FormLabel, Grid, GridItem, Heading, Input, Link, Select } from "@chakra-ui/react";
import { GoogleMap, InfoWindowF, MarkerF, useLoadScript } from "@react-google-maps/api";
import Device, { DeviceWithNotifications } from "../../models/device";
import { GOOGLE_MAPS_API_KEY } from "../../services/createAuthClient";
import React from "react";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { fetchProjects } from "../../actions/projectAction";
import { fetchOrganizations } from "../../actions/organizationAction";
import { useCustomToast } from "../../hooks/useCustomToast";
import mapsStyles from "../../utils/mapsUtils";
import useCustomAbility from "../../hooks/useCustomAbility";
import { Can } from "../../context/abilityContext";

interface Filter {
    name: string;
    project: string;
    organization: string;
    minNotification: string;
    maxNotification: string;
    isLinkedToProject: string;

}

const DeviceMaps: React.FC = () => {
    const { status, error } = useStateHandling("devices");
    const dispatch = useAppDispatch();
    const showToast = useCustomToast();
    const ability = useCustomAbility();
    const navigate = useNavigate();
    const devicesWithNotifications = useSelector(
        (state: RootState) => state.devices.devicesWithNotifications
    );
    const projects = useSelector((state: RootState) => state.devices.projects);
    const organizations = useSelector((state: RootState) => state.devices.organizations);

    useEffect(() => {
        if (!ability.can("List", "Project_Devices")) {
            navigate("/");
        } else {
            dispatch(FetchDevicesWithNotifications());
            dispatch(fetchProjects());
            dispatch(fetchOrganizations());
        }
    }, [dispatch]);


    const { isLoaded } = useLoadScript({
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    });

    const [activeMarker, setActiveMarker] = useState<Device | null>(null);
    const [infoWindowPosition, setInfoWindowPosition] = useState<{ lat: number, lng: number } | null>(null);
    const [filteredDevices, setFilteredDevices] = useState<DeviceWithNotifications[]>(devicesWithNotifications);
    const [notificationsLengths, setNotificationsLengths] = useState<number[]>([]);
    const [filteredDevicesNotificationsLengths, setFilteredDevicesNotificationsLengths] = useState<number[]>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [filters, setFilters] = useState<Filter>({
        name: '',
        project: '',
        organization: '',
        minNotification: '',
        maxNotification: '',
        isLinkedToProject: ''
    });

    const getNotificationsLength = useCallback((deviceWithNotifications: any) => {
        return deviceWithNotifications.notifications ? deviceWithNotifications.notifications.length : 0;
    }, []);

    useEffect(() => {
        const filtered = filterDevices(devicesWithNotifications, filters);
        setFilteredDevices(filtered);
    }, [devicesWithNotifications, filters]);

    useEffect(() => {
        setActiveMarker(null);
        setFilteredDevicesNotificationsLengths(filteredDevices.map(getNotificationsLength));

    }, [filteredDevices]);

    const handleActiveMarker = useCallback((marker: Device, position: { lat: number, lng: number }) => {
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
        setInfoWindowPosition(position);
    }, [activeMarker]);

    const handleInfoWindowClose = useCallback(() => {
        setActiveMarker(null);
    }
        , []);

    const handleFilterChange = useCallback((event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        setFilters({
            ...filters,
            [event.target.name]: event.target.value
        });
    }, [filters]);

    const handleDeleteFilters = useCallback(() => {
        setFilters({
            name: '',
            project: '',
            organization: '',
            minNotification: '',
            maxNotification: '',
            isLinkedToProject: ''
        });
    }, []);

    const filterDevices = useCallback((devicesWithNotifications: DeviceWithNotifications[], filters: Filter) => {
        setNotificationsLengths(devicesWithNotifications.map(getNotificationsLength));
        const deviceF = devicesWithNotifications.filter((devicesWithNotification, index) => {
            const minNotificationMatch = !filters.minNotification || notificationsLengths[index] >= parseInt(filters.minNotification);
            const maxNotificationMatch = !filters.maxNotification || notificationsLengths[index] <= parseInt(filters.maxNotification);
            const nameMatch = devicesWithNotification.device.name && devicesWithNotification.device.name.toLowerCase().includes(filters.name.toLowerCase())
            const projectMatch = !filters.project || devicesWithNotification.device.projectId === filters.project;
            const organizationMatch = !filters.organization || devicesWithNotification.device.organizationId === filters.organization;
            let isLinkedToProjectMatch = !filters.isLinkedToProject;
            if (filters.isLinkedToProject === "true") {
                isLinkedToProjectMatch = devicesWithNotification.device.projectId !== null;
            } else if (filters.isLinkedToProject === "false") {
                isLinkedToProjectMatch = devicesWithNotification.device.projectId === null;
            }

            return minNotificationMatch && maxNotificationMatch && nameMatch && projectMatch && organizationMatch && isLinkedToProjectMatch;
        });

        if (isLoaded && deviceF.length === 0) {
            showToast({
                type: 'warning',
                title: 'No devices found',
                description: 'Try to change the filters',
            });
        }

        return deviceF;

    }, [notificationsLengths]);

    const getMarkesIcons = useCallback((notificationsNumber: number) => {
        if (notificationsNumber === 0) {
            return 'http://maps.google.com/mapfiles/ms/icons/green-dot.png';
        } else if (notificationsNumber > 0 && notificationsNumber < 5) {
            return 'http://maps.google.com/mapfiles/ms/icons/yellow-dot.png';
        } else {
            return 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
        }
    }, []);

    return (
        <Box height="75vh">
            <StatusHandler status={status} error={error}>
                <Heading mb={5}>Device maps</Heading>
                <Box mb={5}>
                    <Box mb={3}>
                        <Button colorScheme="blue" variant="outline" onClick={() => setIsOpen(!isOpen)}>
                            {isOpen ? 'Hide filters' : 'Show filters'}
                        </Button>
                        <Box ml={3} display="inline">
                            {isOpen && (<Button colorScheme="red" variant="outline" onClick={handleDeleteFilters}>
                                Delete filters
                            </Button>)}
                        </Box>
                    </Box>

                    <Collapse in={isOpen} animateOpacity>
                        <Grid templateColumns="repeat(3, 1fr)" gap={6} justifyContent="center">
                            <GridItem>
                                <FormLabel>Device name</FormLabel>
                                <Input
                                    name="name"
                                    onChange={handleFilterChange}
                                    value={filters.name}
                                />
                            </GridItem>
                            <GridItem>
                                <FormLabel>Project</FormLabel>
                                <Select onChange={handleFilterChange} name="project" value={filters.project}>
                                    <option value="" defaultValue={""}>Select an option</option>
                                    {projects.map((project, index) => (
                                        <option key={index} value={project.id}>
                                            {project.name}
                                        </option>
                                    ))}
                                </Select>
                            </GridItem>
                            <GridItem>
                                <FormLabel>Organization</FormLabel>
                                <Select onChange={handleFilterChange} name="organization" value={filters.organization}>
                                    <option value="" defaultValue={""}>Select an option</option>
                                    {organizations.map((organization, index) => (
                                        <option key={index} value={organization.id}>
                                            {organization.name}
                                        </option>
                                    ))}
                                </Select>
                            </GridItem>
                            <GridItem>
                                <FormLabel>Minimum notification number</FormLabel>
                                <Input
                                    name="minNotification"
                                    type="number"
                                    onChange={handleFilterChange}
                                    value={filters.minNotification}
                                />
                            </GridItem>
                            <GridItem>
                                <FormLabel>Maximum notification number</FormLabel>
                                <Input
                                    name="maxNotification"
                                    type="number"
                                    onChange={handleFilterChange}
                                    value={filters.maxNotification}
                                />
                            </GridItem>
                            <Can I="List" a="Organization_Devices">
                                <GridItem>
                                    <FormLabel>Device is linked to project</FormLabel>
                                    <Select onChange={handleFilterChange} name="isLinkedToProject" value={filters.isLinkedToProject}>
                                        <option value="" defaultValue={""}>Select an option</option>
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </Select>
                                </GridItem>
                            </Can>
                        </Grid>

                    </Collapse>
                </Box>

                {isLoaded && (
                    <GoogleMap
                        mapContainerStyle={{
                            width: '100%',
                            height: '100%'
                        }}
                        center={infoWindowPosition || {
                            lat: 46,
                            lng: 25.5
                        }}
                        zoom={7}
                        options={{
                            styles: mapsStyles
                        }}
                    >
                        {filteredDevices && filteredDevices.length > 0 && (
                            filteredDevices.map((deviceWithNotifications, index) => (
                                deviceWithNotifications.device && deviceWithNotifications.device.geolocationLatitude && deviceWithNotifications.device.geolocationLongitude && (
                                    <MarkerF
                                        key={index}
                                        position={{
                                            lat: deviceWithNotifications.device.geolocationLatitude as number,
                                            lng: deviceWithNotifications.device.geolocationLongitude as number
                                        }}
                                        onClick={() => handleActiveMarker(
                                            deviceWithNotifications.device,
                                            {
                                                lat: deviceWithNotifications.device.geolocationLatitude as number,
                                                lng: deviceWithNotifications.device.geolocationLongitude as number
                                            }
                                        )}
                                        icon={{
                                            url: getMarkesIcons(filteredDevicesNotificationsLengths[index]),
                                        }}

                                    >
                                        {activeMarker === deviceWithNotifications.device && (
                                            <InfoWindowF onCloseClick={handleInfoWindowClose}>
                                                <p style={{ color: 'black', textAlign: 'center' }}>
                                                    <strong>{deviceWithNotifications.device.name}</strong>
                                                    <br />
                                                    Notifications number: {filteredDevicesNotificationsLengths[index]}
                                                    <br />
                                                    <Link
                                                        as={RouterLink}
                                                        to={`/projects/${deviceWithNotifications.device.projectId}/notifications/${deviceWithNotifications.device.deviceHardwareId}`}
                                                        style={{ textDecoration: 'none' }}
                                                    >
                                                        Notification details
                                                    </Link>
                                                </p>
                                            </InfoWindowF>
                                        )}
                                    </MarkerF>
                                )
                            ))
                        )}
                    </GoogleMap>
                )}
            </StatusHandler>
        </Box>
    );
};

export default DeviceMaps;