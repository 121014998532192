import Joi from "joi";
import Form, { FormFieldProps } from "../../components/form";
import { Box, Heading } from "@chakra-ui/react";
import StatusHandler from "../../components/shared/StatusHandler";
import useStateHandling from "../../hooks/useStateHandling";
import { useNavigate } from "react-router-dom";
import useAppDispatch from "../../hooks/useAppDispatch";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { createOrganization } from "../../actions/organizationAction";
import Organization from "../../models/organization";
import { useCallback, useEffect } from "react";
import useCustomAbility from "../../hooks/useCustomAbility";

const organizationSchema = Joi.object({
    name: Joi.string().required(),
    email: Joi.string()
        .email({ tlds: { allow: false } })
        .required(),
    phoneNumber: Joi.string().required(),
});

const organizationFields: FormFieldProps[] = [
    {
        name: "name",
        label: "Name",
        type: "text",
        required: true,
        placeHolder: "Enter name",
    },
    {
        name: "email",
        label: "Email",
        type: "email",
        required: true,
        placeHolder: "Enter email",
    },
    {
        name: "phoneNumber",
        label: "Phone number",
        type: "tel",
        required: true,
        placeHolder: "Enter phone number",
    },
];

const OrganizationCreate: React.FC = () => {
    const { status, error } = useStateHandling("organization");
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const methods = useForm({
        resolver: joiResolver(organizationSchema),
    });
    const ability = useCustomAbility();

    useEffect(() => {
        if (!ability.can("Create", "Organization")) {
            navigate("/");
        }
    }, [navigate]);

    const handleFormSubmit = useCallback(
        (data: Organization) => {
            dispatch(createOrganization(data));
            navigate("/organizations");
        },
        [dispatch, navigate]
    );

    return (
        <Box>
            <StatusHandler status={status} error={error}>
                <Heading>Create organization</Heading>
                <Box mt={6} width="100%" overflowX="auto">
                    <Form
                        fields={organizationFields}
                        onSubmit={handleFormSubmit}
                        submitText="Create organization"
                        methods={methods}
                    />
                </Box>
            </StatusHandler>
        </Box>
    );
};

export default OrganizationCreate;
