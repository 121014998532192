import { createColumnHelper } from "@tanstack/react-table";
import User from "../../models/user";
import { ProjectFull } from "../../models/project";
import useAppDispatch from "../../hooks/useAppDispatch";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useCallback, useMemo } from "react";
import { DeleteIcon } from "@chakra-ui/icons";
import { Box, Button, Heading, VStack } from "@chakra-ui/react";
import { DataTable } from "../../components/shared/table/DataTable";
import {
    deleteProjectAdmin,
    deleteProjectUser,
    fetchProjectNonAdminUsers,
    fetchProjectNonUsers,
} from "../../actions/projectAction";
import AddUserOrAdminToProjectModal from "./AddUserOrAdminToProjectModal";
import { Can } from "../../context/abilityContext";

const userColumnHelper = createColumnHelper<User>();

type ProjectAdminOrUserDetailsProps = {
    project: ProjectFull;
    id: string;
    isOpen: boolean;
    isAdmin: boolean;
    onOpen: () => void;
    onClose: () => void;
};

const ProjectAdminOrUserDetails: React.FC<ProjectAdminOrUserDetailsProps> = ({
    project,
    id,
    isAdmin,
    isOpen,
    onOpen,
    onClose,
}) => {
    const dispatch = useAppDispatch();

    const nonAdminUsers = useSelector(
        (state: RootState) => state.project.nonAdminUsers
    );

    const nonProjectUsers = useSelector(
        (state: RootState) => state.project.nonProjectUsers
    );

    const modalUsers = isAdmin ? nonAdminUsers : nonProjectUsers;

    const handleUserDelete = useCallback(
        (user: User) => {
            if (id && user.id) {
                if (isAdmin) {
                    dispatch(deleteProjectAdmin({ id: id, userId: user.id }));
                } else {
                    dispatch(deleteProjectUser({ id: id, userId: user.id }));
                }
            }
        },
        [dispatch, id, isAdmin]
    );

    const handleAddModal = useCallback(() => {
        if (id) {
            if (isAdmin) {
                dispatch(fetchProjectNonAdminUsers({ id: id }));
            } else {
                dispatch(fetchProjectNonUsers({ id: id }));
            }
        }
        onOpen();
    }, [dispatch, id, isAdmin, onOpen]);

    const userColumns = useMemo(
        () => [
            userColumnHelper.accessor("name", {
                cell: (info) => info.getValue(),
                header: "Name",
            }),
            userColumnHelper.accessor("username", {
                cell: (info) => info.getValue(),
                header: "Username",
            }),
            userColumnHelper.accessor("email", {
                cell: (info) => info.getValue(),
                header: "Email",
            }),
            userColumnHelper.display({
                id: "delete",
                cell: (info) => (
                    <Can I="Delete" a="Project_User">
                        <Box>
                            <Button
                                leftIcon={<DeleteIcon />}
                                colorScheme="red"
                                variant="outline"
                                onClick={() => handleUserDelete(info.row.original)}
                            >
                                Delete
                            </Button>
                        </Box>
                    </Can>
                ),
            }),
        ],
        [handleUserDelete]
    );

    return (
        <VStack spacing={8} alignItems="start">
            <Box mt={4}>
                {isAdmin && <Heading size="md">Admins</Heading>}
                {!isAdmin && <Heading size="md">Users</Heading>}
            </Box>
            <Box width="100%" overflowX="auto">
                {isAdmin && project?.admins && project.admins.length > 0 && (
                    <DataTable columns={userColumns} data={project.admins} />
                )}
                {!isAdmin && project?.users && project.users.length > 0 && (
                    <DataTable columns={userColumns} data={project.users} />
                )}
            </Box>
            <Can I="Add" a="Project_User">
                <Box mt={2}>
                    <Button
                        colorScheme="blue"
                        variant="outline"
                        onClick={() => handleAddModal()}
                    >
                        {isAdmin && "Add admin to project"}
                        {!isAdmin && "Add user to project"}
                    </Button>
                    {id && modalUsers && (
                        <AddUserOrAdminToProjectModal
                            projectId={id}
                            users={modalUsers}
                            isOpen={isOpen}
                            onClose={onClose}
                            adminModal={isAdmin}
                        />
                    )}
                </Box>
            </Can>
        </VStack>
    );
};

export default ProjectAdminOrUserDetails;
