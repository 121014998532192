import { ChakraProvider, theme } from "@chakra-ui/react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Layout from "./components/shared/Layout";
import { ToastProvider } from "./context/toastContext";
import RegisterPage from "./pages/auth/RegistrationPage";
import LoginPage from "./pages/auth/LoginPage";
import LogoutPage from "./pages/auth/LogoutPage";
import OrganizationList from "./pages/organization/OrganizationList";
import OrganizationDetails from "./pages/organization/OrganizationDetails";
import OrganizationCreate from "./pages/organization/OrganizationCreate";
import OrganizationEdit from "./pages/organization/OrganizationEdit";
import ProjectList from "./pages/project/ProjectList";
import ProjectEdit from "./pages/project/ProjectEdit";
import ProjectDetails from "./pages/project/ProjectDetails";
import DeviceAdmin from "./pages/device/AllDeviceList";
import DeviceCreate from "./pages/device/DeviceCreate";
import DeviceEdit from "./pages/device/DeviceEdit";
import DeviceMaps from "./pages/device/DeviceMaps";
import NotificationDeviceList from "./pages/notification/NotificationDeviceList";
import AllNotificationList from "./pages/notification/AllNotificationList";
import AbilityProvider from "./context/abilityContext";
import Home from "./pages/Home";

export const App = () => (
  <ChakraProvider theme={theme}>
    <ToastProvider>
      <AbilityProvider>
        <Router>
          <Layout>
            <Routes>
              <Route index path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />

              <Route path="/auth/register" element={<RegisterPage />} />
              <Route path="/auth/login" element={<LoginPage />} />
              <Route path="/auth/logout" element={<LogoutPage />} />

              <Route path="devices" element={<DeviceAdmin />} />
              <Route path="devices/create" element={<DeviceCreate />} />\
              <Route path="devices/maps" element={<DeviceMaps />} />
              <Route path="devices/:deviceId/edit" element={<DeviceEdit />} />

              <Route path="/organizations" element={<OrganizationList />} />
              <Route path="/organizations/create" element={<OrganizationCreate />} />
              <Route path="/organizations/edit/:id" element={<OrganizationEdit />} />
              <Route path="/organizations/:id" element={<OrganizationDetails />} />

              <Route path="/projects" element={<ProjectList />} />
              <Route path="/projects/edit/:id" element={<ProjectEdit />} />
              <Route path="/projects/:id" element={<ProjectDetails />} />

              <Route path="/projects/:projectId/notifications/:notificationId/" element={<NotificationDeviceList />} />

              <Route path="/notifications" element={<AllNotificationList />} />

              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Layout>
        </Router>
      </AbilityProvider>
    </ToastProvider>
  </ChakraProvider>
);
