import { useForm } from "react-hook-form";
import User from "../../models/user";
import { joiResolver } from "@hookform/resolvers/joi";
import useAppDispatch from "../../hooks/useAppDispatch";
import Form, { FormFieldProps } from "../../components/form";
import Joi from "joi";
import { useCallback, useMemo } from "react";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader } from "@chakra-ui/react";
import { addAdminToProject, addUserToProject } from "../../actions/projectAction";

interface AddUserOrAdminToProjectModalProps {
    projectId: string;
    users: User[];
    isOpen: boolean;
    onClose: () => void;
    adminModal: boolean;
    }

const addUserSchema = Joi.object({
    user: Joi.string().required(),
});

const AddUserOrAdminToProjectModal: React.FC<AddUserOrAdminToProjectModalProps> = ({
    projectId,
    users,
    isOpen,
    onClose,
    adminModal,
}) => {
    const dispatch = useAppDispatch();

    const addUserFields: FormFieldProps[] = useMemo(
        () => [
            {
                name: "user",
                label: "Users",
                element: "select",
                required: true,
                options: users.map((user) => ({
                    value: user.id as string,
                    label: user.name as string,
                })),
            },
        ],
        [users]
    );

    const methods = useForm({
        resolver: joiResolver(addUserSchema),
    });

    const handleOnSubmit = useCallback(
        (data: any) => {
            if (projectId && data && adminModal) {
                dispatch(
                    addAdminToProject({
                        id: projectId,
                        userId: data.user as string,
                    })
                );
                onClose();
            }
            else if (data && !adminModal) {
                dispatch(
                    addUserToProject({
                        id: projectId,
                        userId: data.user as string,
                    })
                );
                onClose();
            }
        },
        [dispatch, onClose, projectId, adminModal]
    );

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalContent>
            {adminModal ? (
              <ModalHeader>Add admin to project</ModalHeader>
            ) : (
              <ModalHeader>Add user to project</ModalHeader>
            )}
            <ModalCloseButton />
            <ModalBody>
              <Form
                fields={addUserFields}
                onSubmit={handleOnSubmit}
                submitText="Save"
                methods={methods}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      );
};

export default AddUserOrAdminToProjectModal;
    