import { createColumnHelper } from "@tanstack/react-table";
import { RootState } from "../../store";
import { shallowEqual, useSelector } from "react-redux";
import useStateHandling from "../../hooks/useStateHandling";
import { useNavigate } from "react-router-dom";
import useAppDispatch from "../../hooks/useAppDispatch";
import { useCallback, useEffect, useMemo } from "react";
import { deleteOrganization, fetchOrganizations } from "../../actions/organizationAction";
import { Box, Button, Flex, Heading, VStack } from "@chakra-ui/react";
import { DeleteIcon, EditIcon, InfoIcon } from "@chakra-ui/icons";
import StatusHandler from "../../components/shared/StatusHandler";
import { DataTable } from "../../components/shared/table/DataTable";
import Organization from "../../models/organization";
import { Can } from "../../context/abilityContext";
import useCustomAbility from "../../hooks/useCustomAbility";

const columnHelper = createColumnHelper<Organization>();

const OrganizationList: React.FC = () => {
  const { status, error } = useStateHandling("organization");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const organizations = useSelector(
    (state: RootState) => state.organization.organizations,
    shallowEqual
  );
  const ability = useCustomAbility();

  useEffect(() => {
    if (!ability.can("List", "Organizations")) {
      navigate("/");
    } else {
      dispatch(fetchOrganizations());
    }
  }, [dispatch, navigate]);

  const handleDetailsClick = useCallback(
    (organization: Organization) => {
      navigate(`/organizations/${organization.id}`);
    },
    [navigate]
  );

  const handleOrganizationEdit = useCallback(
    (organization: Organization) => {
      navigate(`/organizations/edit/${organization.id}`);
    },
    [navigate]
  );

  const handleOrganizationDelete = useCallback(
    (organization: Organization) => {
      dispatch(deleteOrganization({ id: organization.id }));
    },
    [dispatch]
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        cell: (info) => info.getValue(),
        header: "Name",
      }),
      columnHelper.accessor("email", {
        cell: (info) => info.getValue(),
        header: "Email",
      }),
      columnHelper.accessor("phoneNumber", {
        cell: (info) => info.getValue(),
        header: "Phone number",
      }),
      columnHelper.display({
        id: "buttons",
        cell: (info) => (
          <Flex justifyContent="flex-end">
            <Can I="Get" a="Organization">
              <Button
                leftIcon={<InfoIcon />}
                colorScheme="gray"
                variant="outline"
                onClick={() => handleDetailsClick(info.row.original)}
              >
                Details
              </Button>
            </Can>
            <Can I="Update" a="Organization">
              <Button
                ml={10}
                leftIcon={<EditIcon />}
                colorScheme="blue"
                variant="outline"
                onClick={() => handleOrganizationEdit(info.row.original)}
              >
                Edit
              </Button>
            </Can>
            <Can I="Delete" a="Organization">
              <Button
                ml={10}
                leftIcon={<DeleteIcon />}
                colorScheme="red"
                variant="outline"
                onClick={() => handleOrganizationDelete(info.row.original)}
              >
                Delete
              </Button>
            </Can>
          </Flex>
        ),
      }),
    ],
    [handleDetailsClick, handleOrganizationEdit, handleOrganizationDelete]
  );

  return (
    <StatusHandler status={status} error={error}>
        <VStack spacing={8} alignItems="start">
          <Heading>Organizations</Heading>
          <Box width="100%" overflowX="auto">
            <DataTable columns={columns} data={organizations} />
          </Box>
          <Can I="Create" a="Organization">
            <Box>
              <Button
                colorScheme="blue"
                onClick={() => navigate("/organizations/create")}
              >
                Create organization
              </Button>
            </Box>
          </Can>
        </VStack>
    </StatusHandler>
  );
};

export default OrganizationList;
