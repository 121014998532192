import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import Project, { ProjectFull } from "../models/project";
import BaseResponse from "../types/BaseResponse";
import createAuthClient from "../services/createAuthClient";
import User from "../models/user";

export type FetchProjectsData = {
    data: Project[];
} & BaseResponse;

export type FetchProjectData = {
    data: ProjectFull;
} & BaseResponse;

export type FetchProjectArgs = {
    id: string;
};

export type CreateProjectArgs = {
    organizationId: string;
    name: string;
};

export type CreateProjectData = {
    data: Project;
} & BaseResponse;

export type UpdateProjectArgs = {
    id: string;
    name: string;
};

export type UpdateProjectData = {
    data: Project;
} & BaseResponse;

export type DeleteProjectArgs = {
    projectId: string;
};

export type DeleteProjectData = {
    data: string;
} & BaseResponse;

export type DeleteProjectAdminOrUserData = {
    data: string;
} & BaseResponse;

export type DeleteProjectAdminOrUserArgs = {
    id: string;
    userId: string;
};

export type FetchNonAdminOrProjectUsersData = {
    data: Array<User>;
} & BaseResponse;

export type FetchNonAdminOrProjectUsersArgs = {
    id: string;
};

export type AddAdminOrUserToProjectData = {
    data: User;
} & BaseResponse;

export type AddAdminOrUserToProjectArgs = {
    id: string;
    userId: string;
};

export type FetchProjectByOrganizationData = {
    data: Project[];
} & BaseResponse;

export type FetchProjectByOrganizationArgs = {
    organizationId: string;
};

export const fetchProjects: AsyncThunk<FetchProjectsData, void, {}> =
    createAsyncThunk<FetchProjectsData>(
        "project/fetchProjects",
        async (_, thunkAPI) => {
            const { get } = createAuthClient(thunkAPI);
            return await get<FetchProjectsData>("api/project");
        }
    );

export const fetchProject: AsyncThunk<FetchProjectData, FetchProjectArgs, {}> =
    createAsyncThunk<FetchProjectData, FetchProjectArgs>(
        "project/fetchProject",
        async ({ id }, thunkAPI) => {
            const { get } = createAuthClient(thunkAPI);
            return await get<FetchProjectData>(`api/project/${id}`);
        }
    );

export const createProject: AsyncThunk<
    CreateProjectData,
    CreateProjectArgs,
    {}
> = createAsyncThunk<CreateProjectData, CreateProjectArgs>(
    "project/createProject",
    async (CreateProjectArgs, thunkAPI) => {
        const { post } = createAuthClient(thunkAPI);
        return await post<CreateProjectData>("api/project", CreateProjectArgs);
    }
);

export const updateProject: AsyncThunk<
    UpdateProjectData,
    UpdateProjectArgs,
    {}
> = createAsyncThunk<UpdateProjectData, UpdateProjectArgs>(
    "project/updateProject",
    async ({ id, name }, thunkAPI) => {
        const { put } = createAuthClient(thunkAPI);
        return await put<UpdateProjectData>(`api/project/${id}`, { name });
    }
);

export const deleteProject: AsyncThunk<
    DeleteProjectData,
    DeleteProjectArgs,
    {}
> = createAsyncThunk<DeleteProjectData, DeleteProjectArgs>(
    "project/deleteOrganizationProject",
    async ({ projectId }, thunkAPI) => {
        const { deleteRequest } = createAuthClient(thunkAPI);

        return await deleteRequest<DeleteProjectData>(
            `api/project/${projectId}`
        );
    }
);

export const deleteProjectAdmin: AsyncThunk<
    DeleteProjectAdminOrUserData,
    DeleteProjectAdminOrUserArgs,
    {}
> = createAsyncThunk<
    DeleteProjectAdminOrUserData,
    DeleteProjectAdminOrUserArgs
>(
    "project/deleteOrganizationProjectAdmin",
    async ({ id, userId }, thunkAPI) => {
        const { deleteRequest } = createAuthClient(thunkAPI);

        return await deleteRequest<DeleteProjectAdminOrUserData>(
            `api/project/${id}/admin/${userId}`
        );
    }
);

export const deleteProjectUser: AsyncThunk<
    DeleteProjectAdminOrUserData,
    DeleteProjectAdminOrUserArgs,
    {}
> = createAsyncThunk<
    DeleteProjectAdminOrUserData,
    DeleteProjectAdminOrUserArgs
>("project/deleteOrganizationProjectUser", async ({ id, userId }, thunkAPI) => {
    const { deleteRequest } = createAuthClient(thunkAPI);

    return await deleteRequest<DeleteProjectAdminOrUserData>(
        `api/project/${id}/user/${userId}`
    );
});

export const fetchProjectNonAdminUsers: AsyncThunk<
    FetchNonAdminOrProjectUsersData,
    FetchNonAdminOrProjectUsersArgs,
    {}
> = createAsyncThunk<
    FetchNonAdminOrProjectUsersData,
    FetchNonAdminOrProjectUsersArgs
>("project/fetchProjectNonAdminUsers", async ({ id }, thunkAPI) => {
    const { get } = createAuthClient(thunkAPI);

    return await get<FetchNonAdminOrProjectUsersData>(
        `api/project/${id}/non-admin-users`
    );
});

export const fetchProjectNonUsers: AsyncThunk<
    FetchNonAdminOrProjectUsersData,
    FetchNonAdminOrProjectUsersArgs,
    {}
> = createAsyncThunk<
    FetchNonAdminOrProjectUsersData,
    FetchNonAdminOrProjectUsersArgs
>("project/fetchProjectNonProjectUsers", async ({ id }, thunkAPI) => {
    const { get } = createAuthClient(thunkAPI);

    return await get<FetchNonAdminOrProjectUsersData>(
        `api/project/${id}/non-project-users`
    );
});

export const addAdminToProject: AsyncThunk<
    AddAdminOrUserToProjectData,
    AddAdminOrUserToProjectArgs,
    {}
> = createAsyncThunk<AddAdminOrUserToProjectData, AddAdminOrUserToProjectArgs>(
    "project/addAdminToProject",
    async ({ id, userId }, thunkAPI) => {
        const { post } = createAuthClient(thunkAPI);

        return await post<AddAdminOrUserToProjectData>(
            `api/project/${id}/admin/${userId}`
        );
    }
);

export const addUserToProject: AsyncThunk<
    AddAdminOrUserToProjectData,
    AddAdminOrUserToProjectArgs,
    {}
> = createAsyncThunk<AddAdminOrUserToProjectData, AddAdminOrUserToProjectArgs>(
    "project/addUserToProject",
    async ({ id, userId }, thunkAPI) => {
        const { post } = createAuthClient(thunkAPI);

        return await post<AddAdminOrUserToProjectData>(
            `api/project/${id}/user/${userId}`
        );
    }
);

export const fetchProjectsByOrganization: AsyncThunk<
    FetchProjectByOrganizationData,
    FetchProjectByOrganizationArgs,
    {}
> = createAsyncThunk<FetchProjectByOrganizationData, FetchProjectByOrganizationArgs>(
    "project/fetchProjectsByOrganization",
    async ({ organizationId }, thunkAPI) => {
        const { get } = createAuthClient(thunkAPI);
        return await get<FetchProjectByOrganizationData>(`api/project/organization/${organizationId}`);
    }
);