import { IconType } from "react-icons";
import { BiLogOut, BiLogIn, BiUserPlus, BiDevices, BiBuildings, BiPlus, BiClipboard, BiMap, BiMessage } from "react-icons/bi";

export interface LinkItemProps {
  name: string;
  icon: IconType;
  to: string;
  action?: string;
  subject?: string;
  subItems?: Array<LinkItemProps>;
  authRequired?: boolean;
}

export interface Group {
  name: LinkItemGroup;
  items: LinkItemProps[];
  authRequired?: boolean;
}

export type LinkItemGroup = "default" | "Devices" | "Profile" | "User" | "Organization" | "Project" | "Admin" | "Notification";

export const LinkItems: Array<Group> = [
  {
    name: "Devices",
    authRequired: true,
    items: [
      {
        name: "Manage devices",
        icon: BiDevices,
        to: "/devices",
        action: "List",
        subject: "Project_Devices",
        authRequired: true,
      },
      {
        name: "Device maps",
        icon: BiMap,
        to: "/devices/maps",
        action: "List",
        subject: "Project_Devices",
        authRequired: true,
      },
      {
        name: "Create device",
        icon: BiPlus,
        to: "/devices/create",
        action: "Create",
        subject: "Device",
        authRequired: true,
      },
    ],
  },
  {
    name: "Notification",
    authRequired: true,
    items: [
      {
        name: "Manage notifications",
        icon: BiMessage,
        to: "/notifications",
        action: "List",
        subject: "Projects_Notifications",
        authRequired: true,
      },
    ],
  },
  {
    name: "Organization",
    authRequired: true,
    items: [
      {
        name: "Manage organizations",
        icon: BiBuildings,
        to: "/organizations",
        action: "List",
        subject: "Organizations",
        authRequired: true,
      },
      {
        name: "Create organization",
        icon: BiPlus,
        to: "/organizations/create",
        action: "Create",
        subject: "Organization",
        authRequired: true,
      }
    ],
  },
  {
    name: "Project",
    authRequired: true,
    items: [
      {
        name: "Manage projects",
        icon: BiClipboard,
        to: "/projects",
        action: "List",
        subject: "Projects",
        authRequired: true,
      }
    ],
  },
  {
    name: "Profile",
    authRequired: true,
    items: [
      {
        name: "Logout",
        icon: BiLogOut,
        to: "/auth/logout",
        authRequired: true,
      },
    ],
  },
  {
    name: "User",
    items: [
      { name: "Login", icon: BiLogIn, to: "/auth/login", authRequired: false },
      {
        name: "Registration",
        icon: BiUserPlus,
        to: "/auth/register",
        authRequired: false,
      },
    ],
  },
];
