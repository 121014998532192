import { shallowEqual, useSelector } from "react-redux";
import useAppDispatch from "../../hooks/useAppDispatch";
import useStateHandling from "../../hooks/useStateHandling";
import { RootState } from "../../store";
import { useEffect } from "react";
import { fetchNotifications } from "../../actions/notificationAction";
import StatusHandler from "../../components/shared/StatusHandler";
import NotificationList from "./NotificationList";
import useCustomAbility from "../../hooks/useCustomAbility";
import { useNavigate } from "react-router-dom";

const AllNotificationList: React.FC = () => {
    const { status, error } = useStateHandling("notification");
    const dispatch = useAppDispatch();
    const ability = useCustomAbility();
    const navigate = useNavigate();
    const notifications = useSelector(
        (state: RootState) => state.notification.notifications,
        shallowEqual
    );

    useEffect(() => {
        if (!ability.can("List", "Projects_Notifications")) {
            navigate("/");
        } else {
            dispatch(fetchNotifications());
        }
    }, [dispatch]);

    return (
        <StatusHandler status={status} error={error}>
            <NotificationList notifications={notifications} />
        </StatusHandler>
    );
}

export default AllNotificationList;