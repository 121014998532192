import { useEffect } from "react";
import { fetchProject } from "../../actions/projectAction";
import { Box, Heading, VStack, useDisclosure } from "@chakra-ui/react";
import { RootState } from "../../store";
import { useNavigate, useParams } from "react-router-dom";
import useStateHandling from "../../hooks/useStateHandling";
import useAppDispatch from "../../hooks/useAppDispatch";
import { useSelector } from "react-redux";
import StatusHandler from "../../components/shared/StatusHandler";
import ProjectAdminOrUserDetails from "./ProjectAdminOrUserDetails";
import { fetchDevicesByProject } from "../../actions/deviceAction";
import DeviceList from "../device/DeviceList";
import useCustomAbility from "../../hooks/useCustomAbility";

type ProjectDetailsParams = {
    id: string;
};

const ProjectDetails: React.FC = () => {
    const { status, error } = useStateHandling("project");
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const ability = useCustomAbility();
    const { id } = useParams<ProjectDetailsParams>();
    const project = useSelector(
        (state: RootState) => state.project.projectFull
    );

    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        isOpen: isOpenUser,
        onOpen: onOpenUser,
        onClose: onCloseUser,
    } = useDisclosure();

    useEffect(() => {
        if (!ability.can("Get", "Project")) {
            navigate("/");
        } else if (id) {
           dispatch(fetchProject({ id: id }));
        }
    }, [dispatch, id, navigate, ability]);

    useEffect(() => {
        if (id) {
            dispatch(fetchDevicesByProject({ projectId: id }));
        }
    }, [dispatch, id, navigate]);

    return (
        <StatusHandler status={status} error={error}>
            <VStack spacing={8} alignItems="start">
                <Heading>{project?.project.name} details</Heading>

                <Box width="100%" overflowX="auto">
                    {project && id && (
                        <Box>
                            <Box mt={4}>
                                <ProjectAdminOrUserDetails
                                    project={project}
                                    id={id}
                                    isAdmin={true}
                                    isOpen={isOpen}
                                    onOpen={onOpen}
                                    onClose={onClose}
                                />
                            </Box>
                            <Box mt={4}>
                                <ProjectAdminOrUserDetails
                                    project={project}
                                    id={id}
                                    isAdmin={false}
                                    isOpen={isOpenUser}
                                    onOpen={onOpenUser}
                                    onClose={onCloseUser}
                                />
                            </Box>
                        </Box>
                    )}
                </Box>

                <>
                    <Heading size="md">Devices</Heading>
                    {project?.devices && (
                        <DeviceList devices={project?.devices} />
                    )}
                </>


            </VStack>
        </StatusHandler>
    );
};

export default ProjectDetails;
