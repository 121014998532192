
import { createColumnHelper } from "@tanstack/react-table";
import Project from "../../models/project";
import useStateHandling from "../../hooks/useStateHandling";
import { useSelector } from "react-redux";
import useAppDispatch from "../../hooks/useAppDispatch";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store";
import { useCallback, useEffect, useMemo } from "react";
import { deleteProject, fetchProjects } from "../../actions/projectAction";
import { Box, Button, Flex, Heading, VStack } from "@chakra-ui/react";
import { DeleteIcon, EditIcon, InfoIcon } from "@chakra-ui/icons";
import StatusHandler from "../../components/shared/StatusHandler";
import { DataTable } from "../../components/shared/table/DataTable";
import useCustomAbility from "../../hooks/useCustomAbility";
import { Can } from "../../context/abilityContext";

const columnHelper = createColumnHelper<Project>();

const ProjectList: React.FC = () => {
    const { status, error } = useStateHandling("project");
    const navigate = useNavigate();
    const ability = useCustomAbility();
    const dispatch = useAppDispatch();
    const projects = useSelector((state: RootState) => state.project.projects);

    useEffect(() => {
        if (!ability.can("List", "Projects")) {
            navigate("/");
        } else {
            dispatch(fetchProjects());
        }
    }, [dispatch, navigate]);

    const handleDetailsClick = useCallback(
        (project: Project) => {
            navigate(`/projects/${project.id}`);
        },
        [navigate]
    );

    const handleProjectEdit = useCallback(
        (project: Project) => {
            navigate(`/projects/edit/${project.id}`);
        },
        [navigate]
    );

    const handleProjectDelete = useCallback(
        (project: Project) => {
            if (project.id) {
                dispatch(deleteProject({ projectId: project.id }));
            }
        },
        [dispatch]
    );

    const columns = useMemo(
        () => [
            columnHelper.accessor("name", {
                cell: (info) => info.getValue(),
                header: "Name",
            }),
            columnHelper.accessor("organizationName", {
                cell: (info) => info.getValue(),
                header: "Organization",
            }),
            columnHelper.display({
                id: "buttons",
                cell: (info) => (
                    <Flex justifyContent="flex-end">
                        <Can I="Get" a="Project">
                            <Button
                                leftIcon={<InfoIcon />}
                                colorScheme="gray"
                                variant="outline"
                                onClick={() =>
                                    handleDetailsClick(info.row.original)
                                }
                            >
                                Details
                            </Button>
                        </Can>
                        <Can I="Update" a="Project">
                            <Button
                                ml={10}
                                leftIcon={<EditIcon />}
                                colorScheme="blue"
                                variant="outline"
                                onClick={() => handleProjectEdit(info.row.original)}
                            >
                                Edit
                            </Button>
                        </Can>
                        <Can I="Delete" a="Project">
                            <Button
                                ml={10}
                                mr={20}
                                leftIcon={<DeleteIcon />}
                                colorScheme="red"
                                variant="outline"
                                onClick={() =>
                                    handleProjectDelete(info.row.original)
                                }
                            >
                                Delete
                            </Button>
                        </Can>
                    </Flex>
                ),
            }),
        ],
        [handleDetailsClick, handleProjectDelete, handleProjectEdit]
    );

    return (
        <StatusHandler status={status} error={error}>
            <VStack spacing={8} alignItems="start">
                <Heading>Projects</Heading>
                <Box width="100%" overflowX="auto">
                    <DataTable columns={columns} data={projects} />
                </Box>
            </VStack>
        </StatusHandler>
    );
};

export default ProjectList;
