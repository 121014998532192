import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  VStack,
  Text,
  HStack,
} from "@chakra-ui/react";
import Configuration from "../../models/configuration";
import { booleanToString } from "../../utils/stringUtils";

interface DeviceConfigurationProps {
  configuration: Configuration;
  isOpen: boolean;
  onClose: () => void;
}

const DeviceConfigurationModal: React.FC<DeviceConfigurationProps> = ({
  configuration,
  isOpen,
  onClose,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader textAlign="center">Device configuration</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="flex-start">
            <Box>
              <HStack>
                <Text fontWeight="bold">Device name:</Text>
                <Text>{configuration.deviceName}</Text>
              </HStack>
            </Box>

            <Box>
              <HStack>
                <Text fontWeight="bold">Record video:</Text>
                <Text>{booleanToString(configuration.recordVideoEnabled)}</Text>
              </HStack>
            </Box>
            <Box>
              <HStack>
                <Text fontWeight="bold">External server path:</Text>
                <Text>{configuration.externalServerPath}</Text>
              </HStack>
            </Box>
            <Box>
              <HStack>
                <Text fontWeight="bold">Hotspot name:</Text>
                <Text>{configuration.hotspotName}</Text>
              </HStack>
            </Box>
            <Box>
              <HStack>
                <Text fontWeight="bold">Hotspot password:</Text>
                <Text>{configuration.hotspotPassword}</Text>
              </HStack>
            </Box> 
            <Box>
              <HStack>
                <Text fontWeight="bold">Username:</Text>
                <Text>{configuration.username}</Text>
              </HStack>
            </Box>
            <Box>
              <HStack>
                <Text fontWeight="bold">Password:</Text>
                <Text>{configuration.password}</Text>
              </HStack>
            </Box>
            <Box>
              <HStack>
                <Text fontWeight="bold">Name:</Text>
                <Text>{configuration.name}</Text>
              </HStack>
            </Box>
            <Box>
              <HStack>
                <Text fontWeight="bold">Email:</Text>
                <VStack align="flex-start">
                  {configuration.email && configuration.email.length > 0
                    ? configuration.email.map((email, index) => (
                        <Text key={index}>{email}</Text>
                      ))
                    : ""}
                </VStack>
              </HStack>
            </Box>
            <Box>
              <HStack>
                <Text fontWeight="bold">X Threshold:</Text>
                <Text>{configuration.xThreshold}</Text>
              </HStack>
            </Box>
            <Box>
              <HStack>
                <Text fontWeight="bold">Y Threshold:</Text>
                <Text>{configuration.yThreshold}</Text>
              </HStack>
            </Box>
            <Box>
              <HStack>
                <Text fontWeight="bold">Duration threshold:</Text>
                <Text>{configuration.durationThreshold}</Text>
              </HStack>
            </Box>
            <Box>
              <HStack>
                <Text fontWeight="bold">Alarm enabled:</Text>
                <Text>{booleanToString(configuration.alarmEnabled)}</Text>
              </HStack>
            </Box>
            <Box>
              <HStack>
                <Text fontWeight="bold">Video duration:</Text>
                <Text>{configuration.videoDuration}</Text>
              </HStack>
            </Box>
            <Box>
              <HStack>
                <Text fontWeight="bold">Max videos size:</Text>
                <Text>{configuration.maxVideosSize}</Text>
              </HStack>
            </Box>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default DeviceConfigurationModal;
