import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { GOOGLE_MAPS_API_KEY } from "../../services/createAuthClient";
import { useCallback, useState } from "react";
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader } from "@chakra-ui/react";
import { Coordinate } from "../../models/device";
import mapsStyles from "../../utils/mapsUtils";

interface DeviceMapsModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSelect: (selectedPosition: Coordinate) => void;
    coordinate: Coordinate | null;
}

const DeviceSelectModal: React.FC<DeviceMapsModalProps> = ({ onSelect, onClose, isOpen, coordinate }) => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    });

    const [selectedPosition, setSelectedPosition] = useState<Coordinate | null>(coordinate || null);

    const handleMapClick = useCallback((e: any) => {
        setSelectedPosition({
            latitude: e.latLng.lat(),
            longitude: e.latLng.lng()
        });
    }, []);

    const handleSelect = useCallback(() => {
        if (selectedPosition) {
            onSelect(selectedPosition);
        }
        onClose();
    }, [onSelect, onClose, selectedPosition]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalContent>
                <ModalHeader>Select device location</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {isLoaded && (
                        <GoogleMap
                            mapContainerStyle={{ height: "60vh", width: "100%" }}
                            center={{
                                lat: selectedPosition?.latitude || 45.657,
                                lng: selectedPosition?.longitude || 25.6
                            }}
                            zoom={8}
                            onClick={handleMapClick}
                            options={{
                                styles: mapsStyles
                            }}
                        >
                        {selectedPosition && selectedPosition.latitude && selectedPosition.longitude && (
                            <MarkerF
                                position={{
                                    lat: selectedPosition.latitude,
                                    lng: selectedPosition.longitude
                                }}
                            />
                        )}
                        </GoogleMap>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" onClick={handleSelect}>Select</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );

}

export default DeviceSelectModal;
