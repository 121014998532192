import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
} from "@chakra-ui/react";

import Notification from "../../models/notification";
import ReactPlayer from "react-player";
import { CLOUD_URI } from "../../services/createAuthClient";

interface VideoModalProps {
  notification: Notification;
  isOpen: boolean;
  onClose: () => void;
}

const VideoModal: React.FC<VideoModalProps> = ({
  notification,
  isOpen,
  onClose,
}) => {
  const formattedDate = notification.timestamp
    ? new Date(notification.timestamp).toLocaleString("ro-RO", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        timeZoneName: "short",
      })
    : "";

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xxl">
      <ModalContent width="auto">
        <ModalHeader>{formattedDate}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ReactPlayer
            url={`${CLOUD_URI}${notification.cloudPath}`}
            controls={true}
            loop={true}
            playing={true}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default VideoModal;
