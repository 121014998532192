import { useNavigate, useParams } from "react-router-dom";
import useAppDispatch from "../../hooks/useAppDispatch";
import useStateHandling from "../../hooks/useStateHandling";
import { RootState } from "../../store";
import { shallowEqual, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchDeviceNotifications } from "../../actions/notificationAction";
import StatusHandler from "../../components/shared/StatusHandler";
import NotificationList from "./NotificationList";
import useCustomAbility from "../../hooks/useCustomAbility";


type NotificationDeviceListParams = {
    notificationId: string;
    projectId: string;
};

const NotificationDeviceList: React.FC = () => {
    const { status, error } = useStateHandling("notification");
    const dispatch = useAppDispatch();
    const { notificationId, projectId } = useParams<NotificationDeviceListParams>();
    const notifications = useSelector(
        (state: RootState) => state.notification.notifications,
        shallowEqual
    );
    const navigate = useNavigate();
    const ability = useCustomAbility();

    useEffect(() => {
        if (!ability.can("List", "Projects_Notifications")) {
            navigate("/");
        }
    }, [dispatch]);

    useEffect(() => {
        if (notificationId && projectId) {
            dispatch(
                fetchDeviceNotifications({
                    notificationId: notificationId,
                })
            );
        }
    }, [dispatch, notificationId, projectId]);

    return (
        <StatusHandler status={status} error={error}>
            <NotificationList notifications={notifications} />
        </StatusHandler>
    );
}

export default NotificationDeviceList;