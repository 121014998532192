import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import BaseResponse from "../types/BaseResponse";
import createAuthClient from "../services/createAuthClient";
import Notification from "../models/notification";

export type FetchNotificationsData = {
  data: Notification[];
} & BaseResponse;

export type DeleteNotificationData = {
  data: string;
} & BaseResponse;

export type FetchNotificationArgs = {
  notificationId: string;
};

export type DeleteNotificationArgs = {
  notificationId: string;
};

export const fetchDeviceNotifications: AsyncThunk<
  FetchNotificationsData,
  FetchNotificationArgs,
  {}
> = createAsyncThunk<FetchNotificationsData, FetchNotificationArgs>(
  "notification/fetcDevicehNotifications",
  async ({ notificationId }, thunkAPI) => {
    const { get } = createAuthClient(thunkAPI);

    return await get<FetchNotificationsData>(`api/notification/device/${notificationId}`);
  }
);

export const fetchNotifications: AsyncThunk<
  FetchNotificationsData,
  void,
  {}
> = createAsyncThunk<FetchNotificationsData, void>(
  "notification/fetcNotifications",
  async (_, thunkAPI) => {
    const { get } = createAuthClient(thunkAPI);

    return await get<FetchNotificationsData>(`api/notification`);
  }
);

export const deleteNotification: AsyncThunk<
  DeleteNotificationData,
  DeleteNotificationArgs,
  {}
> = createAsyncThunk<DeleteNotificationData, DeleteNotificationArgs>(
  "notification/deleteNotification",
  async ({ notificationId }, thunkAPI) => {
    const { deleteRequest } = createAuthClient(thunkAPI);

    return await deleteRequest<DeleteNotificationData>(`api/notification/${notificationId}`);
  }
);

