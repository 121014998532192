import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { Permission } from '../models/permission';
import { MongoAbility } from '@casl/ability';
import { defineAbilities } from '../ability/defineAbilities';
import { useMemo } from 'react';

const useCustomAbility = (): MongoAbility => {
  const permissions: Permission[] =
    useSelector((state: RootState) => state.auth.userData.permissions) || [] as Permission[];
  return useMemo(() => defineAbilities(permissions), [permissions]);
};

export default useCustomAbility;