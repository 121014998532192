import { combineReducers } from "redux";
import authReducer from "../reducers/authReducer";
import deviceReducer from "./deviceReducer";
import notificationReducer from "./notificationReducer";
import organizationReducer from "./organizationReducer";
import projectReducer from "./projectReducer";

const rootReducer = combineReducers({
  devices: deviceReducer,
  auth: authReducer,
  notification: notificationReducer,
  organization: organizationReducer,
  project: projectReducer,
});

export default rootReducer;
