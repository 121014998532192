import Joi from "joi";
import { joiResolver } from "@hookform/resolvers/joi";
import { useForm } from "react-hook-form";
import Form, { FormFieldProps } from "../../components/form";
import { useMemo } from "react";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader } from "@chakra-ui/react";

type DeviceEmailModalProps = {
    isOpen: boolean;
    onClose: () => void;
    setEmail: (email: string) => void;

};

const addUserSchema = Joi.object({
    email: Joi.string().required(),
});

const DeviceEmailModal: React.FC<DeviceEmailModalProps> = ({
    isOpen,
    onClose,
    setEmail,
}) => {
    const methods = useForm({
        resolver: joiResolver(addUserSchema),
    });

    const handleOnSubmit = (data: any) => {
        if (data) {
            setEmail(data.email);
            onClose();
        }
    };

    const addUserFields: FormFieldProps[] = useMemo(
        () => [
            {
                name: "email",
                label: "Email",
                element: "input",
                required: true,
                value: "",
            },
        ],
        []
    );

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalContent>
                <ModalHeader>Add new email address to device</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Form
                        fields={addUserFields}
                        onSubmit={handleOnSubmit}
                        submitText="Save"
                        methods={methods}
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export default DeviceEmailModal;