import { AbilityBuilder, createMongoAbility } from '@casl/ability';
import { Permission } from '../models/permission';

export function defineAbilities(permissions: Permission[]) {
  const { can, build } = new AbilityBuilder(createMongoAbility);
  permissions.forEach((permission) => {
    const action = permission.name.split('_')[0];
    const name = permission.name.replace(`${action}_`, '');
    can(action, name);
  });

  return build();
}
