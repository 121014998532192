import React from 'react';
import Loading from './Loading';
import Error from './Error';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from '../../store';

interface StatusHandlerProps {
  status: string;
  error: string | null | undefined;
  children: React.ReactNode;
}

const StatusHandler: React.FC<StatusHandlerProps> = ({ status, error, children }) => {
  const user = useSelector((state: RootState) => state.auth.userData.user);

  if (!user) {
    return <Navigate to={'/login'} />;
  }
  
  if (status === 'loading') {
    return <Loading />;
  }

  if (status === 'failed') {
    return <Error title="An error occurred" description={error ?? ''} />;
  }

  return <>{children}</>;
};

export default StatusHandler;
