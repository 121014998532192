import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import Organization from "../models/organization";
import BaseResponse from "../types/BaseResponse";
import createAuthClient from "../services/createAuthClient";
import { OrganizationFull } from "../models/organization";
import User from "../models/user";
import Project from "../models/project";

export type FetchOrganizationsData = {
    data: Organization[];
} & BaseResponse;

export type FetchOrganizationData = {
    data: OrganizationFull;
} & BaseResponse;

export type FetchOrganizationArgs = {
    id: string;
};

export type CreateOrganizationData = {
    data: Organization;
} & BaseResponse;

export type CreateOrganizationArgs = {
    name: string;
    email: string;
    phoneNumber: string;
};

export type DeleteOrganizationArgs = {
    id: string;
};

export type DeleteOrganizationData = {
    data: string;
} & BaseResponse;

export type DeleteOrganizationAdminOrUserData = {
    data: string;
} & BaseResponse;

export type DeleteOrganizationAdminOrUserArgs = {
    id: string;
    userId: string;
};

export type DeleteOrganizationProjectData = {
    data: string;
} & BaseResponse;

export type DeleteOrganizationProjectArgs = {
    id: string;
    projectId: string;
};

export type GetNonAdminUsersData = {
    data: Array<User>;
} & BaseResponse;

export type GetNonAdminUsersArgs = {
    id: string;
};

export type AddAdminToOrganizationData = {
    data: User;
} & BaseResponse;

export type AddAdminToOrganizationArgs = {
    id: string;
    userId: string;
};

export type AddUserToOrganizationData = {
    data: User;
} & BaseResponse;

export type AddUserToOrganizationArgs = {
    id: string;
    email: string;
};

export type AddProjectToOrganizationData = {
    data: Project;
} & BaseResponse;

export type AddProjectToOrganizationArgs = {
    id: string;
    projectId: string;
};

export type FetchUsersWithoutOrganizationData = {
    data: Array<User>;
} & BaseResponse;

export type UpdateOrganizationData = {
    data: Organization;
} & BaseResponse;

export type UpdateOrganizationArgs = {
    id: string;
    name: string;
    email: string;
    phoneNumber: string;
};

export const fetchOrganizations: AsyncThunk<FetchOrganizationsData, void, {}> =
    createAsyncThunk<FetchOrganizationsData, void>(
        "organization/fetchOrganizations",
        async (_, thunkAPI) => {
            const { get } = createAuthClient(thunkAPI);

            return await get<FetchOrganizationsData>("api/organization");
        }
    );

export const fetchOrganization: AsyncThunk<
    FetchOrganizationData,
    FetchOrganizationArgs,
    {}
> = createAsyncThunk<FetchOrganizationData, FetchOrganizationArgs>(
    "organization/fetchOrganization",
    async ({ id }, thunkAPI) => {
        const { get } = createAuthClient(thunkAPI);

        return await get<FetchOrganizationData>(`api/organization/${id}`);
    }
);

export const createOrganization: AsyncThunk<
    CreateOrganizationData,
    CreateOrganizationArgs,
    {}
> = createAsyncThunk<CreateOrganizationData, CreateOrganizationArgs>(
    "organization/createOrganization",
    async (CreateOrganizationArgs, thunkAPI) => {
        const { post } = createAuthClient(thunkAPI);

        return await post<CreateOrganizationData>(
            `api/admin/organization`,
            CreateOrganizationArgs
        );
    }
);

export const deleteOrganization: AsyncThunk<
    DeleteOrganizationData,
    DeleteOrganizationArgs,
    {}
> = createAsyncThunk<DeleteOrganizationData, DeleteOrganizationArgs>(
    "organization/deleteOrganization",
    async ({ id }, thunkAPI) => {
        const { deleteRequest } = createAuthClient(thunkAPI);

        return await deleteRequest<DeleteOrganizationData>(
            `api/admin/organization/${id}`
        );
    }
);

export const deleteOrganizationAdmin: AsyncThunk<
    DeleteOrganizationAdminOrUserData,
    DeleteOrganizationAdminOrUserArgs,
    {}
> = createAsyncThunk<
    DeleteOrganizationAdminOrUserData,
    DeleteOrganizationAdminOrUserArgs
>("organization/deleteOrganizationAdmin", async ({ id, userId }, thunkAPI) => {
    const { deleteRequest } = createAuthClient(thunkAPI);

    return await deleteRequest<DeleteOrganizationAdminOrUserData>(
        `api/organization/${id}/admin/${userId}`
    );
});

export const deleteOrganizationUser: AsyncThunk<
    DeleteOrganizationAdminOrUserData,
    DeleteOrganizationAdminOrUserArgs,
    {}
> = createAsyncThunk<
    DeleteOrganizationAdminOrUserData,
    DeleteOrganizationAdminOrUserArgs
>("organization/deleteOrganizationUser", async ({ id, userId }, thunkAPI) => {
    const { deleteRequest } = createAuthClient(thunkAPI);

    return await deleteRequest<DeleteOrganizationAdminOrUserData>(
        `api/organization/${id}/user/${userId}`
    );
});

export const fetchNonAdminUsers: AsyncThunk<
    GetNonAdminUsersData,
    GetNonAdminUsersArgs,
    {}
> = createAsyncThunk<GetNonAdminUsersData, GetNonAdminUsersArgs>(
    "organization/fetchNonAdminUsers",
    async ({ id }, thunkAPI) => {
        const { get } = createAuthClient(thunkAPI);

        return await get<GetNonAdminUsersData>(
            `api/organization/${id}/non-admin-users`
        );
    }
);

export const addAdminToOrganization: AsyncThunk<
    AddAdminToOrganizationData,
    AddAdminToOrganizationArgs,
    {}
> = createAsyncThunk<
    AddAdminToOrganizationData,
    AddAdminToOrganizationArgs
>("organization/addAdminToOrganization", async ({ id, userId }, thunkAPI) => {
    const { post } = createAuthClient(thunkAPI);
    return await post<AddAdminToOrganizationData>(
        `api/organization/${id}/admin/${userId}`
    );
});

export const addUserToOrganization: AsyncThunk<
    AddUserToOrganizationData,
    AddUserToOrganizationArgs,
    {}
> = createAsyncThunk<
    AddUserToOrganizationData,
    AddUserToOrganizationArgs
>("organization/addUsersToOrganization", async ({ id, email }, thunkAPI) => {
    const { post } = createAuthClient(thunkAPI);
    return await post<AddUserToOrganizationData>(
        `api/organization/${id}/user/${email}`
    );
});

export const updateOrganization: AsyncThunk<
    UpdateOrganizationData,
    UpdateOrganizationArgs,
    {}
> = createAsyncThunk<UpdateOrganizationData, UpdateOrganizationArgs>(
    "organization/updateOrganization",
    async ({ id, name, email, phoneNumber }, thunkAPI) => {
        const { put } = createAuthClient(thunkAPI);
        return await put<UpdateOrganizationData>(`api/organization/${id}`, {
            name,
            email,
            phoneNumber,
        });
    }
);

export const addProjectToOrganization: AsyncThunk<
    AddProjectToOrganizationData,
    AddProjectToOrganizationArgs,
    {}
> = createAsyncThunk<
    AddProjectToOrganizationData,
    AddProjectToOrganizationArgs
>(
    "organization/addProjectToOrganization",
    async ({ id, projectId }, thunkAPI) => {
        const { post } = createAuthClient(thunkAPI);
        return await post<AddProjectToOrganizationData>(
            `api/organization/${id}/project/${projectId}`
        );
    }
);
