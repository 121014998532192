import { CustomRootState, DefaultState } from "../store/state";
import { mapAsyncThunkToGlobalAction } from "../actions";
import { wrapSliceWithCommonFunctions } from "../hoc/reducerWrapper";
import {
    AddAdminToOrganizationData,
    AddUserToOrganizationData,
    AddProjectToOrganizationData,
    CreateOrganizationData,
    DeleteOrganizationAdminOrUserData,
    DeleteOrganizationProjectData,
    FetchOrganizationData,
    FetchOrganizationsData,
    GetNonAdminUsersData,
    UpdateOrganizationData,
    addAdminToOrganization,
    addProjectToOrganization,
    addUserToOrganization,
    createOrganization,
    deleteOrganizationAdmin,
    deleteOrganizationUser,
    fetchNonAdminUsers,
    fetchOrganization,
    fetchOrganizations,
    updateOrganization,
    deleteOrganization,
} from "../actions/organizationAction";
import { OrganizationFull } from "../models/organization";
import Organization from "../models/organization";
import User from "../models/user";
import { CreateProjectData, createProject, deleteProject } from "../actions/projectAction";

export interface OrganizationState {
    organizationFull?: OrganizationFull;
    organizations: Array<Organization>;
    organization?: Organization;
    nonAdminUsers?: Array<User>;
}

type OrganizationStateWithRootState = OrganizationState & CustomRootState;

const InitialState: OrganizationStateWithRootState = {
    ...DefaultState,
    nonAdminUsers: [],
    organizations: [],
    organizationFull: undefined,
    organization: undefined,
};

const organizationSlice = wrapSliceWithCommonFunctions({
    name: "organization",
    initialState: InitialState,
    reducers: {
        clearData: (state) => {
            state.organizationFull = undefined;
            state.organizations = [];
            state.organization = undefined;
            state.nonAdminUsers = [];
        },
    },
    extraReducers: (builder) => {
        mapAsyncThunkToGlobalAction<
            OrganizationStateWithRootState,
            FetchOrganizationsData
        >(builder, fetchOrganizations, {
            pending: (state) => {
                state.status = "loading";
                state.organizations = [];
            },
            fulfilled: (state, action) => {
                state.status = "succeeded";
                state.requestStatus = action.requestStatus;
                state.organizations = action.payload.data;
            },
            rejected: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.requestStatus = action.requestStatus;
            },
        });

        mapAsyncThunkToGlobalAction<
            OrganizationStateWithRootState,
            FetchOrganizationData
        >(builder, fetchOrganization, {
            pending: (state) => {
                state.status = "loading";
                state.organization = undefined;
            },
            fulfilled: (state, action) => {
                state.status = "succeeded";
                state.requestStatus = action.requestStatus;
                state.organizationFull = action.payload.data;
            },
            rejected: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.requestStatus = action.requestStatus;
            },
        });

        mapAsyncThunkToGlobalAction<
            OrganizationStateWithRootState,
            CreateOrganizationData
        >(builder, createOrganization, {
            pending: (state) => {
                state.status = "loading";
            },
            fulfilled: (state, action) => {
                state.status = "succeeded";
                state.requestStatus = action.requestStatus;
                state.organization = action.payload.data;
                state.organizations.push(action.payload.data);
            },
            rejected: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.requestStatus = action.requestStatus;
            },
        });

        mapAsyncThunkToGlobalAction<
            OrganizationStateWithRootState,
            DeleteOrganizationProjectData
        >(builder, deleteOrganization, {
            pending: (state) => {
                state.status = "loading";
            },
            fulfilled: (state, action) => {
                state.status = "succeeded";
                state.requestStatus = action.requestStatus;
                state.organizations = state.organizations.filter(
                    (organization) => organization.id !== action.payload.data
                );
                state.organization = undefined;
                state.organizationFull = undefined;
            },
            rejected: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.requestStatus = action.requestStatus;
            },
        });

        mapAsyncThunkToGlobalAction<
            OrganizationStateWithRootState,
            DeleteOrganizationAdminOrUserData
        >(builder, deleteOrganizationAdmin, {
            pending: (state) => {
                state.status = "loading";
            },
            fulfilled: (state, action) => {
                state.status = "succeeded";
                state.requestStatus = action.requestStatus;

                if (state.nonAdminUsers) {
                    const deletedAdmin = state.organizationFull?.admins?.find(
                        (user) => user.id === action.payload.data
                    );
                    state.nonAdminUsers = [
                        ...state.nonAdminUsers!,
                        deletedAdmin!,
                    ];
                }
                if (state.organizationFull) {
                    const deletedAdmin = state.organizationFull.admins?.find(
                        (user) => user.id === action.payload.data
                    );
                    state.organizationFull.admins =
                        state.organizationFull.admins?.filter(
                            (admin) => admin.id !== action.payload.data
                        );
                    state.organizationFull.users = [
                        ...(state.organizationFull.users ?? []),
                        deletedAdmin!,
                    ];
                }
                if (state.organization) {
                    state.organization.adminIds =
                        state.organization.adminIds?.filter(
                            (adminId) => adminId !== action.payload.data
                        );
                    state.organization.userIds = [
                        ...(state.organization.userIds ?? []),
                        action.payload.data,
                    ];
                }
            },
            rejected: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.requestStatus = action.requestStatus;
            },
        });

        mapAsyncThunkToGlobalAction<
            OrganizationStateWithRootState,
            DeleteOrganizationAdminOrUserData
        >(builder, deleteOrganizationUser, {
            pending: (state) => {
                state.status = "loading";
            },
            fulfilled: (state, action) => {
                state.status = "succeeded";
                state.requestStatus = action.requestStatus;

                if (state.organizationFull) {
                    state.organizationFull.users =
                        state.organizationFull.users?.filter(
                            (user) => user.id !== action.payload.data
                        );
                }
                if (state.organization) {
                    state.organization.userIds =
                        state.organization.userIds?.filter(
                            (userId) => userId !== action.payload.data
                        );
                }
            },
            rejected: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.requestStatus = action.requestStatus;
            },
        });

        mapAsyncThunkToGlobalAction<
            OrganizationStateWithRootState,
            DeleteOrganizationProjectData
        >(builder, deleteProject, {
            pending: (state) => {
                state.status = "loading";
            },
            fulfilled: (state, action) => {
                state.status = "succeeded";
                state.requestStatus = action.requestStatus;
                if (state.organizationFull) {
                    state.organizationFull.projects =
                        state.organizationFull.projects?.filter(
                            (project) => project.id !== action.payload.data
                        );
                }
                if (state.organization) {
                    state.organization.projectIds =
                        state.organization.projectIds?.filter(
                            (projectId) => projectId !== action.payload.data
                        );
                }
            },
            rejected: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.requestStatus = action.requestStatus;
            },
        });

        mapAsyncThunkToGlobalAction<
            OrganizationStateWithRootState,
            GetNonAdminUsersData
        >(builder, fetchNonAdminUsers, {
            pending: (state) => {
                state.status = "loading";
                state.nonAdminUsers = [];
            },
            fulfilled: (state, action) => {
                state.status = "succeeded";
                state.requestStatus = action.requestStatus;
                state.nonAdminUsers = action.payload.data;
            },
            rejected: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.requestStatus = action.requestStatus;
            },
        });

        mapAsyncThunkToGlobalAction<
            OrganizationStateWithRootState,
            AddAdminToOrganizationData
        >(builder, addAdminToOrganization, {
            pending: (state) => {
                state.status = "loading";
            },
            fulfilled: (state, action) => {
                state.status = "succeeded";
                state.requestStatus = action.requestStatus;
                const addedUser = action.payload.data;
                state.nonAdminUsers = state.nonAdminUsers?.filter(
                    (user) => user.id !== action.payload.data.id
                );
                if (state.organizationFull) {
                    state.organizationFull.admins = [
                        ...(state.organizationFull.admins ?? []),
                        addedUser!,
                    ];
                    state.organizationFull.users = state.organizationFull.users?.filter(
                        (user) => user.id !== addedUser!.id
                    );
                }
                if (state.organization) {
                    state.organization.adminIds = [
                        ...(state.organization.adminIds ?? []),
                        addedUser!.id,
                    ];
                    state.organization.userIds = state.organization.userIds?.filter(
                        (userId) => userId !== addedUser!.id
                    );
                }

            },
            rejected: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.requestStatus = action.requestStatus;
            },
        });

        mapAsyncThunkToGlobalAction<
            OrganizationStateWithRootState,
            AddUserToOrganizationData
        >(builder, addUserToOrganization, {
            pending: (state) => {
                state.status = "loading";
            },
            fulfilled: (state, action) => {
                state.status = "succeeded";
                state.requestStatus = action.requestStatus;
                const addedUser = action.payload.data;

                if (state.organizationFull) {
                    state.organizationFull.users = [
                        ...(state.organizationFull.users ?? []),
                        addedUser!,
                    ];
                }
                if (state.organization) {
                    state.organization.userIds = [
                        ...(state.organization.userIds ?? []),
                        addedUser!.id,
                    ];
                }
            },
            rejected: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.requestStatus = action.requestStatus;
            },
        });

        mapAsyncThunkToGlobalAction<
            OrganizationStateWithRootState,
            UpdateOrganizationData
        >(builder, updateOrganization, {
            pending: (state) => {
                state.status = "loading";
            },
            fulfilled: (state, action) => {
                state.status = "succeeded";
                state.requestStatus = action.requestStatus;
                state.organization = action.payload.data;
                if (state.organizationFull) {
                    state.organizationFull.organization = action.payload.data;
                }
                if (state.organizations) {
                    state.organizations = state.organizations.map(
                        (organization) =>
                            organization.id === action.payload.data.id
                                ? action.payload.data
                                : organization
                    );
                }
            },
            rejected: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.requestStatus = action.requestStatus;
            },
        });

        mapAsyncThunkToGlobalAction<
            OrganizationStateWithRootState,
            AddProjectToOrganizationData
        >(builder, addProjectToOrganization, {
            pending: (state) => {
                state.status = "loading";
            },
            fulfilled: (state, action) => {
                state.status = "succeeded";
                state.requestStatus = action.requestStatus;
                const addedProject = action.payload.data;

                if (state.organizationFull) {
                    state.organizationFull.projects = [
                        ...(state.organizationFull.projects ?? []),
                        addedProject!,
                    ];
                }
                if (state.organization) {
                    state.organization.projectIds = [
                        ...(state.organization.projectIds ?? []),
                        addedProject!.id,
                    ];
                }
            },
            rejected: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.requestStatus = action.requestStatus;
            },
        });

        mapAsyncThunkToGlobalAction<
            OrganizationStateWithRootState,
            CreateProjectData
        >(builder, createProject, {
            pending: (state) => {
                state.status = "loading";
            },
            fulfilled: (state, action) => {
                state.status = "succeeded";
                state.requestStatus = action.requestStatus;
                state.organizationFull?.projects?.push(action.payload.data);
                state.organization?.projectIds?.push(action.payload.data.id);
            },
            rejected: (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
                state.requestStatus = action.requestStatus;
            },
        });
    },
});

export const { clearData } = organizationSlice.actions;

export default organizationSlice.reducer;
