import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import BaseResponse from "../types/BaseResponse";
import Device, { DeviceWithNotifications } from "../models/device";
import createAuthClient from "../services/createAuthClient";

export type FetchDevicesData = {
    data: Device[];
} & BaseResponse;

export type CreateDeviceData = {
    data: Device;
} & BaseResponse;

export type CreateDeviceArgs = {
    deviceHardwareId: string;
    name: string;
} & BaseResponse;

export type FetchDevicesByProjectData = {
    data: Device[];
} & BaseResponse;

export type FetchDevicesProjectArgs = {
    projectId: string;
} & BaseResponse;

export type UnlinkDeviceData = {
    data: Device;
} & BaseResponse;

export type UnlinkDeviceArgs = {
    deviceId: string;
    projectId: string;
};

export type LinkDeviceData = {
    data: Device;
} & BaseResponse;

export type LinkDeviceArgs = {
    projectId: string;
    deviceId: string;
};

export type DeleteDeviceData = {
    data: string;
} & BaseResponse;

export type DeleteDeviceArgs = {
    deviceId: string;
};

export type FetchDeviceArgs = {
    deviceId: string;
};

export type FetchDeviceData = {
    data: Device;
} & BaseResponse;

export type FetchDevicesWithNotificationsData = {
    data: Array<DeviceWithNotifications>
}

export type UpdateDeviceData = {
    data: Device;
} & BaseResponse;

export type UpdateDeviceArgs = {
    id: string;
    name: string;
    deviceHardwareId: string;
    geolocationLatitude: number;
    geolocationLongitude: number;
    username: string;
    password: string;
    configurationName: string;
    hotspotName: string;
    hotspotPassword: string;
    recordVideoEnabled: boolean;
    externalServerPath: string;
    deviceName: string;
    xThreshold: number;
    yThreshold: number;
    durationThreshold: number;
    alarmEnabled: boolean;
    videoDuration: number;
    maxVideosSize: number;
    email: string[];
};

export const fetchDevicesAdmin: AsyncThunk<FetchDevicesData, void, {}> =
    createAsyncThunk<FetchDevicesData>(
        "device/fetchDevices",
        async (_, thunkAPI) => {
            const { get } = createAuthClient(thunkAPI);
            return await get<FetchDevicesData>("api/device");
        }
    );

export const FetchDevicesWithNotifications: AsyncThunk<FetchDevicesWithNotificationsData, void, {}> =
        createAsyncThunk<FetchDevicesWithNotificationsData>(
            "device/fetchDeviceWithNotifications",
            async (_, thunkApi) => {
                const { get } = createAuthClient(thunkApi);
                return await get<FetchDevicesWithNotificationsData>("api/device/device-with-notifications")
            }
        )

export const createDeviceAdmin: AsyncThunk<
    CreateDeviceData,
    CreateDeviceArgs,
    {}
> = createAsyncThunk<CreateDeviceData, CreateDeviceArgs>(
    "device/createDeviceAdmin",
    async (device, thunkAPI) => {
        const { post } = createAuthClient(thunkAPI);
        return await post<CreateDeviceData>("api/admin/device", device);
    }
);

export const fetchDevicesByProject: AsyncThunk<
    FetchDevicesByProjectData,
    FetchDevicesProjectArgs,
    {}
> = createAsyncThunk<FetchDevicesByProjectData, FetchDevicesProjectArgs>(
    "device/fetchDevicesByProject",
    async ({ projectId }, thunkAPI) => {
        const { get } = createAuthClient(thunkAPI);
        return await get<FetchDevicesByProjectData>(
            `api/project/${projectId}/device`
        );
    }
);

export const fetchDevice: AsyncThunk<FetchDeviceData, FetchDeviceArgs, {}> =
    createAsyncThunk<FetchDeviceData, FetchDeviceArgs>(
        "device/fetchDevice",
        async ({ deviceId }, thunkAPI) => {
            const { get } = createAuthClient(thunkAPI);
            return await get<FetchDeviceData>(`api/device/${deviceId}`);
        }
    );

export const unlinkDeviceFromProject: AsyncThunk<
    UnlinkDeviceData,
    UnlinkDeviceArgs,
    {}
> = createAsyncThunk<UnlinkDeviceData, UnlinkDeviceArgs>(
    "device/unlinkDeviceFromProject",
    async ({ deviceId, projectId }, thunkAPI) => {
        const { deleteRequest } = createAuthClient(thunkAPI);

        return await deleteRequest<UnlinkDeviceData>(
            `api/project/${projectId}/device/${deviceId}/unlink`
        );
    }
);

export const linkDeviceToProject: AsyncThunk<
    LinkDeviceData,
    LinkDeviceArgs,
    {}
> = createAsyncThunk<LinkDeviceData, LinkDeviceArgs>(
    "device/linkDeviceToProject",
    async ({ projectId, deviceId }, thunkAPI) => {
        const { post } = createAuthClient(thunkAPI);

        return await post<LinkDeviceData>(
            `api/project/${projectId}/device/${deviceId}/link`
        );
    }
);

export const deleteDevice: AsyncThunk<DeleteDeviceData, DeleteDeviceArgs, {}> =
    createAsyncThunk<DeleteDeviceData, DeleteDeviceArgs>(
        "device/deleteDevice",
        async ({ deviceId }, thunkAPI) => {
            const { deleteRequest } = createAuthClient(thunkAPI);
            return await deleteRequest<DeleteDeviceData>(
                `api/admin/device/${deviceId}`
            );
        }
    );

export const updateDevice: AsyncThunk<UpdateDeviceData, UpdateDeviceArgs, {}> =
    createAsyncThunk<UpdateDeviceData, UpdateDeviceArgs>(
        "device/updateDevice",
        async (
            {
                id,
                name,
                deviceHardwareId,
                geolocationLatitude,
                geolocationLongitude,
                username,
                password,
                configurationName,
                hotspotName,
                hotspotPassword,
                recordVideoEnabled,
                externalServerPath,
                deviceName,
                xThreshold,
                yThreshold,
                durationThreshold,
                alarmEnabled,
                email,
                videoDuration,
                maxVideosSize,
            },
            thunkAPI
        ) => {
            const { put } = createAuthClient(thunkAPI);

            const configuration = {
                name: configurationName,
                recordVideoEnabled,
                externalServerPath,
                deviceName,
                hotspotName,
                hotspotPassword,
                xThreshold,
                yThreshold,
                durationThreshold,
                alarmEnabled,
                email,
                videoDuration,
                maxVideosSize,
                username,
                password,
            };

            return await put<UpdateDeviceData>(`api/device/${id}`, {
                name,
                deviceHardwareId,
                geolocationLatitude,
                geolocationLongitude,
                configuration
            });
        }
    );
