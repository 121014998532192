import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import Sidebar from './Sidebar';
import { Global } from '@emotion/react';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <Global
        styles={`
          ::-webkit-scrollbar {
            width: 8px;
          }
          ::-webkit-scrollbar-thumb {
            background: #46525B;
            border-radius: 10px;
            transition: background 0.5s ease-in-out;

            :hover {
              background: #4E5C66;
              border-radius: 10px;
            }
          }`
        }
      />
      <Flex height="100vh">
        <Sidebar>
          <Box width="100%" p={4}>
            {children}
          </Box>
        </Sidebar>
      </Flex>
    </>
  );
};

export default Layout;
