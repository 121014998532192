import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
} from "@chakra-ui/react";
import Form from "../../components/form";
import Joi from "joi";
import { FormFieldProps } from "../../components/form";
import { joiResolver } from "@hookform/resolvers/joi";
import { useForm } from "react-hook-form";
import User from "../../models/user";
import { useCallback, useMemo } from "react";
import { addAdminToOrganization } from "../../actions/organizationAction";
import useAppDispatch from "../../hooks/useAppDispatch";

interface AddAdminModalProps {
  organizationId: string;
  users: User[];
  isOpen: boolean;
  onClose: () => void;
}

const addUserSchema = Joi.object({
  user: Joi.string().required(),
});

const AddAdminModal: React.FC<AddAdminModalProps> = ({
  organizationId,
  users,
  isOpen,
  onClose,
}) => {
  const dispatch = useAppDispatch();

  const addUserFields: FormFieldProps[] = useMemo(
    () => [
      {
        name: "user",
        label: "Users",
        element: "select",
        required: true,
        options: users.map((user) => ({
          value: user.id as string,
          label: user.name as string,
        })),
      },
    ],
    [users]
  );

  const methods = useForm({
    resolver: joiResolver(addUserSchema),
  });

  const handleOnSubmit = useCallback(
    (data: any) => {
      if (organizationId && data) {
        dispatch(
          addAdminToOrganization({
            id: organizationId,
            userId: data.user as string,
          })
        );
        onClose();
      }
    },
    [dispatch, onClose, organizationId]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalContent>
        <ModalHeader>Add admin to organization</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Form
            fields={addUserFields}
            onSubmit={handleOnSubmit}
            submitText="Save"
            methods={methods}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddAdminModal;
