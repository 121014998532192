import { mapAsyncThunkToGlobalAction } from "../actions";
import { DeleteNotificationData, FetchNotificationsData, deleteNotification, fetchDeviceNotifications, fetchNotifications } from "../actions/notificationAction";
import { wrapSliceWithCommonFunctions } from "../hoc/reducerWrapper";
import { CustomRootState, DefaultState } from "../store/state";
import Notification from "../models/notification";

export interface NotificationState {
  notification?: Notification;
  notifications: Array<Notification>;
}

type NotificationStateWithRootState = NotificationState & CustomRootState;

const InitialState: NotificationStateWithRootState = {
  ...DefaultState,
  notifications: [],
  notification: undefined
};

const notificationSlice = wrapSliceWithCommonFunctions({
  name: 'notification',
  initialState: InitialState,
  reducers: {
    clearData: (state) => {
      state.notification = undefined;
      state.notifications = [];
    }
  },
  extraReducers: (builder) => {
    mapAsyncThunkToGlobalAction<NotificationStateWithRootState, FetchNotificationsData>(builder, fetchDeviceNotifications, {
      pending: state => {
        state.status = 'loading';
        state.notifications = [];
      },
      fulfilled: (state, action) => {
        state.status = 'succeeded';
        state.requestStatus = action.requestStatus;
        state.notifications = action.payload.data
      },
      rejected: (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.requestStatus = action.requestStatus;
      }
    });

    mapAsyncThunkToGlobalAction<NotificationStateWithRootState, FetchNotificationsData>(builder, fetchNotifications, {
      pending: state => {
        state.status = 'loading';
        state.notifications = [];
      },
      fulfilled: (state, action) => {
        state.status = 'succeeded';
        state.requestStatus = action.requestStatus;
        state.notifications = action.payload.data
      },
      rejected: (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.requestStatus = action.requestStatus;
      }
    });

    mapAsyncThunkToGlobalAction<NotificationStateWithRootState, DeleteNotificationData>(builder, deleteNotification, {
      pending: state => {
        state.status = 'loading';
      },
      fulfilled: (state, action) => {
        state.status = 'succeeded';
        state.requestStatus = action.requestStatus;
        state.notifications = state.notifications.filter(notification => notification.id !== action.payload.data);
      },
      rejected: (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.requestStatus = action.requestStatus;
      }
    });
  }
});

export const { clearData } = notificationSlice.actions;

export default notificationSlice.reducer;