import { useNavigate, useParams } from "react-router-dom";
import useStateHandling from "../../hooks/useStateHandling";
import useAppDispatch from "../../hooks/useAppDispatch";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import Joi from "joi";
import { Box, Button, Flex, Heading, VStack, useDisclosure } from "@chakra-ui/react";
import StatusHandler from "../../components/shared/StatusHandler";
import Form from "../../components/form";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { fetchDevice, updateDevice } from "../../actions/deviceAction";
import Device, { Coordinate } from "../../models/device";
import DeviceSelectModal from "./DeviceSelectModal";
import { DeleteIcon } from "@chakra-ui/icons";
import { DataTable } from "../../components/shared/table/DataTable";
import DeviceEmailModal from "./DeviceEmailModal";
import useCustomAbility from "../../hooks/useCustomAbility";

type DeviceEditParams = {
    deviceId: string;
    projectId: string;
};

type emailType = {
    email: string;
};

const deviceSchema = Joi.object({
    name: Joi.string().required(),
    deviceHardwareId: Joi.string().required(),
    geolocationLatitude: Joi.number().required(),
    geolocationLongitude: Joi.number().required(),
    username: Joi.string().required(),
    password: Joi.string().required(),
    hotspotName: Joi.string().required(),
    hotspotPassword: Joi.string().required(),
    videoDuration: Joi.number().required(),
    maxVideosSize: Joi.number().required(),
    configurationName: Joi.string().required(),
    recordVideoEnabled: Joi.boolean().required(),
    externalServerPath: Joi.string().required(),
    deviceName: Joi.string().required(),
    xThreshold: Joi.number().required(),
    yThreshold: Joi.number().required(),
    durationThreshold: Joi.number().required(),
    alarmEnabled: Joi.boolean().required(),
});

const DeviceEdit: React.FC = () => {
    const { status, error } = useStateHandling("devices");
    const { deviceId, projectId } = useParams<DeviceEditParams>();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const ability = useCustomAbility();
    const initialDevice = useSelector((state: RootState) => state.devices.device);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isEmailModalOpen, onOpen: onEmailModalOpen, onClose: onEmailModalClose } = useDisclosure();
    const [device, setDevice] = useState<Device | null>(initialDevice as Device | null);
    const [emails, setEmails] = useState<string[]>([]);
    const [newEmail, setNewEmail] = useState<string>("");

    useEffect(() => {
        if (!ability.can("Update", "Device")) {
            navigate("/");
        } else if (deviceId) {
            dispatch(fetchDevice({
                deviceId: deviceId
            }));
        }
    }, [deviceId, dispatch, projectId]);

    useEffect(() => {
        if (initialDevice?.configuration?.email) {
            setEmails(initialDevice.configuration.email);
        } else {
            setEmails([]);
        }
    }, [initialDevice]);

    useEffect(() => {
        if (newEmail) {
            setEmails([...emails, newEmail]);
            setNewEmail("");
        }
    }, [newEmail]);

    useEffect(() => {
        setDevice(initialDevice as Device);
    }, [initialDevice]);

    const coordinateOnSelect = useCallback(
        (coordinate: Coordinate) => {
            if (device) {
                const updatedDevice = {
                    ...device,
                    geolocationLatitude: coordinate.latitude,
                    geolocationLongitude: coordinate.longitude,
                };
                setDevice(updatedDevice);
            }
        },
        [device]
    );

    const handleEmailDelete = useCallback(
        (email: emailType) => {
            const updatedEmails = emails.filter((e) => e !== email.email);
            setEmails(updatedEmails);
        },
        [emails]
    );

    const columns = useMemo(
        () => [
            {
                accessor: "email",
                cell: (info: any) => (
                    <Flex justifyContent="space-between" alignItems="center">
                        <Box>{info.row.original.email}</Box>
                        <Button
                            leftIcon={<DeleteIcon />}
                            colorScheme="red"
                            variant="outline"
                            onClick={() => handleEmailDelete(info.row.original)}
                        >
                            Delete
                        </Button>
                    </Flex>
                ),
                header: "Email",
            },
        ],
        [handleEmailDelete]
    );

    const handleSelectDeviceClick = useCallback(() => {
        onOpen();
    }, [onOpen]);

    const handleAddEmailClick = useCallback(() => {
        setNewEmail("");
        onEmailModalOpen();
    }, [onEmailModalOpen]);

    const deviceFields = useMemo(
        () => [
            {
                name: "name",
                label: "Name",
                type: "text",
                required: true,
                value: device?.name as string | undefined,
            },
            {
                name: "deviceHardwareId",
                label: "Device hardware id",
                type: "text",
                required: true,
                value: device?.deviceHardwareId as string | undefined,
            },
            {
                name: "configurationName",
                label: "Configuration name",
                type: "text",
                required: true,
                value: device?.configuration?.name as string | undefined,
            },
            {
                name: "username",
                label: "Username",
                type: "text",
                required: true,
                value: device?.configuration?.username as string | undefined,
            },
            {
                name: "password",
                label: "Password",
                type: "text",
                required: true,
                value: device?.configuration?.password as string | undefined,
            },
            {
                name: "hotspotName",
                label: "Hotspot name",
                type: "text",
                required: true,
                value: device?.configuration?.hotspotName as string | undefined,
            },
            {
                name: "hotspotPassword",
                label: "Hotspot password",
                type: "text",
                required: true,
                value: device?.configuration?.hotspotPassword as string | undefined,
            },
            {
                name: "recordVideoEnabled",
                label: "Record video enabled",
                element: "select",
                required: true,
                value: device?.configuration?.recordVideoEnabled as string | undefined,
                options: [
                    {
                        value: "true",
                        label: "Yes",
                    },
                    {
                        value: "false",
                        label: "No",
                    },
                ]
            },
            {
                name: "externalServerPath",
                label: "External server path",
                type: "text",
                required: true,
                value: device?.configuration?.externalServerPath as string | undefined,
            },
            {
                name: "videoDuration",
                label: "Video duration",
                type: "number",
                required: true,
                value: device?.configuration?.videoDuration as string | undefined,
            },
            {
                name: "deviceName",
                label: "Device name",
                type: "text",
                required: true,
                value: device?.configuration?.deviceName as string | undefined,
            },
            {
                name: "durationThreshold",
                label: "Duration threshold",
                type: "number",
                required: true,
                value: device?.configuration?.durationThreshold as number | undefined,
            },
            {
                name: "alarmEnabled",
                label: "Alarm enabled",
                element: "select",
                required: true,
                value: device?.configuration?.alarmEnabled as string | undefined,
                options: [
                    {
                        value: "true",
                        label: "Yes",
                    },
                    {
                        value: "false",
                        label: "No",
                    },
                ]
            },
            {
                name: "xThreshold",
                label: "X threshold",
                type: "number",
                required: true,
                value: device?.configuration?.xThreshold as number | undefined,
            },
            {
                name: "yThreshold",
                label: "Y threshold",
                type: "number",
                required: true,
                value: device?.configuration?.yThreshold as number | undefined,
            },
            {
                name: "maxVideosSize",
                label: "Max videos size",
                type: "number",
                required: true,
                value: device?.configuration?.maxVideosSize as number | undefined,
            },
            {
                name: "geolocationLatitude",
                label: "Geolocation latitude",
                type: "number",
                required: true,
                value: device?.geolocationLatitude as number | '',
            },
            {
                name: "geolocationLongitude",
                label: "Geolocation longitude",
                type: "number",
                required: true,
                value: device?.geolocationLongitude as number | ''
            },
        ],
        [device]
    );

    const methods = useForm({
        resolver: joiResolver(deviceSchema),
    });

    const handleFormSubmit = useCallback(
        (data: any) => {
            if (deviceId) {
                dispatch(
                    updateDevice({
                        id: deviceId,
                        name: data.name,
                        deviceHardwareId: data.deviceHardwareId,
                        geolocationLatitude: data.geolocationLatitude as number,
                        geolocationLongitude: data.geolocationLongitude as number,
                        username: data.username as string,
                        password: data.password as string,
                        configurationName: data.name as string,
                        hotspotName: data.hotspotName as string,
                        hotspotPassword: data.hotspotPassword as string,
                        recordVideoEnabled: data.recordVideoEnabled as boolean,
                        externalServerPath: data.externalServerPath as string,
                        deviceName: data.deviceName as string,
                        xThreshold: data.xThreshold as number,
                        yThreshold: data.yThreshold as number,
                        durationThreshold: data.durationThreshold as number,
                        alarmEnabled: data.alarmEnabled as boolean,
                        videoDuration: data.videoDuration as number,
                        maxVideosSize: data.maxVideosSize as number,
                        email: emails,
                    })
                );
            }
            navigate("/devices");
        },
        [deviceId, emails, dispatch, navigate]
    );

    return (
        <Box>
            <StatusHandler status={status} error={error}>
                <Heading>Edit device</Heading>
                <VStack spacing={4} mt={8}>
                    <Box mt={6} width="100%" overflowX="auto">
                        {device?.configuration && (
                            <Box>
                                <Box mb={4} mt={4}>
                                    <DataTable columns={columns} data={emails.map((email) => ({ email }))} />
                                    <Box mt={4}>
                                        <Button onClick={handleAddEmailClick} width="100%">Add new email</Button>
                                    </Box>
                                </Box>
                                <Form
                                    fields={deviceFields}
                                    onSubmit={handleFormSubmit}
                                    methods={methods}
                                    submitText="Save"
                                />
                                <Box mb={4} mt={4} width="100%">
                                    <Button onClick={handleSelectDeviceClick} width="100%">Select device location on map</Button>
                                </Box>
                            </Box>
                        )

                        }
                        {!device?.configuration &&
                            <p>No configuration found</p>
                        }
                        {device && (
                            <>
                                <DeviceSelectModal
                                    isOpen={isOpen}
                                    onClose={onClose}
                                    onSelect={coordinateOnSelect}
                                    coordinate={{
                                        latitude: device.geolocationLatitude as number,
                                        longitude: device.geolocationLongitude as number,
                                    }}
                                />

                                <DeviceEmailModal
                                    isOpen={isEmailModalOpen}
                                    onClose={onEmailModalClose}
                                    setEmail={setNewEmail}
                                />
                            </>

                        )}
                    </Box>
                </VStack>
            </StatusHandler>
        </Box>
    );
}

export default DeviceEdit;