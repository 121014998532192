import { createContext } from "react";
import { createContextualCan } from "@casl/react";
import { createMongoAbility, MongoAbility } from "@casl/ability";
import useCustomAbility from "../hooks/useCustomAbility";

const defaultAbility = createMongoAbility([]);
export const AbilityContext = createContext<MongoAbility>(defaultAbility);
export const Can = createContextualCan(AbilityContext.Consumer);

interface AbilityContextProps {
    children: React.ReactNode;
}

const AbilityProvider: React.FC<AbilityContextProps> = ({ children }) => {
    const ability = useCustomAbility();
    return (
        <AbilityContext.Provider value={ability}>
            {children}
        </AbilityContext.Provider>
    );
};

export default AbilityProvider;
