import { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import {
    fetchDevicesAdmin,
} from "../../actions/deviceAction";
import { RootState } from "../../store";
import useAppDispatch from "../../hooks/useAppDispatch";
import StatusHandler from "../../components/shared/StatusHandler";
import useStateHandling from "../../hooks/useStateHandling";
import DeviceList from "./DeviceList";
import { Heading } from "@chakra-ui/react";
import useCustomAbility from "../../hooks/useCustomAbility";
import { useNavigate } from "react-router-dom";


const DeviceAdmin: React.FC = () => {
    const { status, error } = useStateHandling("devices");
    const dispatch = useAppDispatch();
    const devices = useSelector(
        (state: RootState) => state.devices.devices,
        shallowEqual
    );
    const ability = useCustomAbility();
    const navigate = useNavigate();

    useEffect(() => {
        if (!ability.can("List", "Project_Devices")) {
            navigate("/");
        } else {
            dispatch(fetchDevicesAdmin());
        }
    }, [dispatch]);



    return (
        <StatusHandler status={status} error={error}>
            <Heading mb={4}>Devices</Heading>
            <DeviceList devices={devices} />
        </StatusHandler>
    );
};

export default DeviceAdmin;
